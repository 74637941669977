import { Table, Tbody } from "@avanadeits/vangogh";
import { useUserGuestAccountContext } from "../../../contexts/GuestAccountContext/UserGuestAccountContextProvider";
import { useUserAccountListContext } from "../../../contexts/CloudAccountContext/UserAccountListContextProvider";
import AccountTableHeaders from "./Table/GuestAccount/Rows/AccountTableHeaders";
import { tableRowAccountFactory } from "./Table/CloudAccountRows/tableRowAccountFactory";
import { tableRowGuestAccountFactory } from "./Table/GuestAccount/tableRowGuestAccountFactory";

const AccountTable = () => {
  const accounts = useUserAccountListContext();
  const guestAccount = useUserGuestAccountContext();

  return (
    <>
      <Table className="IP-current-account">
        <AccountTableHeaders />
        <Tbody>
          {accounts?.map((account, index) => {
            return tableRowAccountFactory(account, index + "accounts");
          })}
          {tableRowGuestAccountFactory(guestAccount)}
        </Tbody>
      </Table>
    </>
  );
};

export default AccountTable;
