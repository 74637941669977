import { Loader } from "@avanadeits/vangogh";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAzureBasicUserInfoContext } from "../../contexts/AzureBasicUserINdoContext/AzureBasicUserINdoContext";

import toast from "react-hot-toast";
import avanadeRequestsService from "../../services/avanadeRequestsService";
import { RequestTypeInfo } from "../../features/source-users/requests-forms/Common/RequestTypeInfo";
import { AvanadeRequestForm } from "../../features/source-users/requests-forms/Avanade/AvanadeRequestForm";
import { AvanadeRequestFormModel } from "../../features/source-users/requests-forms/Avanade/AvanadeRequestFormModel";
import { FormStatus } from "../../features/source-users/requests-forms/Common/FormStatus";
import { mailboxValidationSchema } from "../../features/source-users/requests-forms/Mailbox/mailboxValidationSchema";

export const AvanadeRequestFormPage = () => {
  const navigate = useNavigate();
  const basicUserAzureInfo = useAzureBasicUserInfoContext();
  const [submitStatus, setSubmitStatus] = useState<FormStatus>("Idle");

  const initialValue: AvanadeRequestFormModel = {
    accentureEmail: basicUserAzureInfo.upn,
    avanadeMangerEnterpriseId: "",
    attachment: undefined,
    id: 0,
    project: { value: "", label: "" },
    projectName: "",
    accentureAccountNotInUserReason: "",
    wbsCode: "",
    businessReason: "",
    attachmentBusinessException: undefined,
  };

  useEffect(() => {
    if (submitStatus === "Success") {
      navigate("/");
    } else if (submitStatus === "Error") {
      navigate("/error");
    }
  }, [navigate, submitStatus]);

  if (submitStatus === "Submitting") return <Loader />;

  const handleSubmit = async (x: AvanadeRequestFormModel) => {
    try {
      setSubmitStatus("Submitting");
      await avanadeRequestsService.createAvanadeRequest(x);
      toast.success("Request submitted.");
      setSubmitStatus("Success");
    } catch (e) {
      setSubmitStatus("Error");
    }
  };

  return (
    <>
      <RequestTypeInfo type="Avanade Account" />
      <AvanadeRequestForm
        handleSubmit={handleSubmit}
        initialValue={initialValue}
        schema={mailboxValidationSchema}
      />
    </>
  );
};
