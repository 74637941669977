import { Routes, Route } from "react-router-dom";
import { AccountDeletionRequestsPage } from "../page/target-users/AccountDeletionRequestsPage";
import { AccountListPage } from "../page/target-users/AccountListPage";
import { ApproveRejectAvanadeRequestPage } from "../page/target-users/ApproveRejectAvanadeRequestPage";
import { ApproveRejectMailboxRequestPage } from "../page/target-users/ApproveRejectMailboxRequestPage";
import { SupportMainPage } from "../page/target-users/mailbox-request-page/MailboxRequestPage";
import { AvanadeRequestsPage } from "../page/target-users/avanade-request-page/AvanadeRequestsPage";
import { OnPremiseRequestPage } from "../page/target-users/on-premise-request-page/OnPremiseRequestPage";
import { ApproveRejectOnPremiseRequestPage } from "../page/target-users/ApproveRejectOnPremiseRequestPage";

type TargetTenantTemplateProps = {
  children: React.ReactNode;
};

const TargetTenantTemplate = (
  props: TargetTenantTemplateProps
): JSX.Element => {
  return <>{props.children}</>;
};

export const TargetTenantAppRoutes = () => {
  return (
    <>
      <TargetTenantTemplate>
        <Routes>
          <Route path="/" element={<AccountListPage />} />
          <Route path="/mailbox-requests" element={<SupportMainPage />} />
          <Route path="/avanade-requests" element={<AvanadeRequestsPage />} />
          <Route
            path="/on-premise-requests"
            element={<OnPremiseRequestPage />}
          />

          <Route
            path="/mailbox-requests/:requestGuid"
            element={<ApproveRejectMailboxRequestPage />}
          />

          <Route
            path="/on-premise-requests/:requestGuid"
            element={<ApproveRejectOnPremiseRequestPage />}
          />
          <Route
            path="/avanade-requests/:requestGuid"
            element={<ApproveRejectAvanadeRequestPage />}
          />

          <Route
            path="/deletion-requests"
            element={<AccountDeletionRequestsPage />}
          />
        </Routes>
      </TargetTenantTemplate>
    </>
  );
};
