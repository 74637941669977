import { BrowserRouter } from "react-router-dom";
import Header from "./layouts/Header";
import { Footer, Loader } from "@avanadeits/vangogh";
import AppRoutes from "./routes/AppRoutes";
import { UserTenantsGatewayContextProvider } from "./contexts/UserTenantsGatewayContext/UserTenantsGatewayContextProvider";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";

function App() {
  const [contentLoaded, setContentLoaded] = useState(false);

  const handleContentLoad = () => {
    setContentLoaded(true);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <UserTenantsGatewayContextProvider onContentLoad={handleContentLoad}>
          {contentLoaded ? <Header /> : <Loader label="Loading..." />}
          <AppRoutes />
          <Footer />
          <Toaster position="top-center" reverseOrder={false} />
        </UserTenantsGatewayContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
