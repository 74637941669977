import {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useContext,
} from "react";
import provisioningService from "../../services/accountGovernanceService";
import { mapAccount } from "./mapAccount";
import { Account } from "./account";

export const useUserAccountListContext = (): Account[] => {
  const accounts = useContext(AccountContext);

  if (accounts === null) {
    console.error("User cloud accounts context is null");
    return [];
  }

  return accounts;
};

const AccountContext = createContext<Account[]>([]);

type Props = {
  children: ReactNode;
};

export const UserAccountListContextProvider = (props: Props) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsLoaded, setAccountsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const accountResponseList = await provisioningService.getUserAccount();

        if (
          accountResponseList?.length !== 0 &&
          Array.isArray(accountResponseList)
        ) {
          const mappedAccounts = accountResponseList.map((account) =>
            mapAccount(account)
          );
          setAccounts(mappedAccounts);
        }
      } catch (error) {
        console.error("Error loading user accounts:", error);
      }
      setAccountsLoaded(true);
    };

    loadData();
  }, []);

  return (
    <AccountContext.Provider value={accounts}>
      {accountsLoaded && props.children}
    </AccountContext.Provider>
  );
};
