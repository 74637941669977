import { Label, Loader, Text } from "@avanadeits/vangogh";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AvanadeRequestIntro } from "../../features/target-users/approve-reject-requests-form/avanade/components/AvandeRequestIntro";
import { StaticAvanadeRequestForm } from "../../features/target-users/approve-reject-requests-form/avanade/StaticAvanadeRequestForm";
import { RejectRequestForm } from "../../features/target-users/approve-reject-requests-form/common/RejectRequestForm";
import { ApproveRequestForm } from "../../features/target-users/approve-reject-requests-form/common/ApproveRequestForm";
import { useLoadAvanadeRequest } from "../../features/target-users/approve-reject-requests-form/avanade/hooks/useLoadAvanadeRequest";
import { RequestTypeInfo } from "../../features/target-users/approve-reject-requests-form/common/RequestTypeInfo";
import avanadeRequestsService from "../../services/avanadeRequestsService";
import { AvanadeRequest } from "../../models/AvanadeRequest";

export const ApproveRejectAvanadeRequestPage = () => {
  const { requestGuid } = useParams();
  const navigator = useNavigate();
  const { request, isRequestLoading } = useLoadAvanadeRequest(requestGuid);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleApprove = async () => {
    try {
      setIsSubmitting(true);
      await avanadeRequestsService.approveAvanadeRequest(request!.guid.value);
      setIsSubmitting(false);
      toast.success("Request approved.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    }
  };

  const handleReject = async (x: { reason: string }) => {
    try {
      setIsSubmitting(true);
      await avanadeRequestsService.rejectAvanadeRequest(
        request?.guid.value!,
        x.reason
      );
      setIsSubmitting(false);
      toast.success("Request rejected.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    }
  };

  if (isRequestLoading) {
    return <Loader />;
  }

  return (
    <>
      <RequestTypeInfo type="Avanade Account" />
      <div className="IP-create-form">
        <AvanadeRequestIntro {...request} />
        {isSubmitting ? (
          <div style={loaderStyle}>
            <Loader />
          </div>
        ) : (
          <>
            <StaticAvanadeRequestForm request={request!} />
            {request?.userRequestStatus === "Pending" && (
              <div className="IP-create-form__fr --for-approve">
                <RejectRequestForm
                  // request={request!}
                  handleReject={handleReject}
                />
                <span></span>
                <ApproveRequestForm handleApprove={handleApprove} />
              </div>
            )}
            {request?.userRequestStatus === "Rejected" && (
              <Label colorOutline={"default"}>
                <Label.Inner>
                  <Label.Header>
                    <Label.HeaderMain>
                      <Text>Reason of rejecting:</Text>
                    </Label.HeaderMain>
                  </Label.Header>
                  <Label.Body>
                    <Text>"{request?.rejectReason}"</Text>
                  </Label.Body>
                </Label.Inner>
              </Label>
            )}
          </>
        )}
      </div>
    </>
  );
};

const loaderStyle = {
  gridColumn: "span 2",
  display: "flex",
  justifyContent: "center",
};
