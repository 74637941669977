import {
  ComponentType,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUserGuestAccountContext } from "../GuestAccountContext/UserGuestAccountContextProvider";
import { useUserAccountListContext } from "../CloudAccountContext/UserAccountListContextProvider";

type RequestFlags =
  | {
      guestAccountRequestEnable: boolean;
      cloudAccountRequestEnabled: boolean;
      anyAccountInUse: true;
    }
  | {
      anyAccountInUse: false;
      guestAccountRequestEnable: false;
      cloudAccountRequestEnabled: false;
    };

export const useRequestsFlagsContext = (): RequestFlags => {
  const context = useContext(RequestsFlagsContext);

  if (context === null)
    throw new Error("Request flags context can not be null");

  return context;
};

interface WithRequestsFlagsProps {}

export const withRequestsFlagsContext = <P extends WithRequestsFlagsProps>(
  Component: ComponentType<P>
) => {
  const WithRequestsFlags: React.FC<P> = (props) => {
    const requestFlags = useRequestsFlagsContext();
    return <Component {...props} requestFlags={requestFlags} />;
  };

  return WithRequestsFlags;
};

export const RequestsFlagsContext = createContext<RequestFlags | null>(null);

type Props = {
  children: ReactNode;
};

export const RequestsFlagsContextProvider = (props: Props) => {
  const [flags, setFlags] = useState<RequestFlags | null>(null);

  var guestAccount = useUserGuestAccountContext();
  var cloudAccount = useUserAccountListContext();

  useEffect(() => {
    const guestAccountInUse = guestAccount.status === "InUse";

    if (cloudAccount !== null && cloudAccount.length > 0) {
      for (const account of cloudAccount) {
        const cloudAccountInUse =
          account.status === "InUse" ||
          account.status === "Disabled" ||
          account.status === "Terminated";

        const anyAccountInUse = guestAccountInUse || cloudAccountInUse;

        if (anyAccountInUse) {
          setFlags({
            anyAccountInUse: anyAccountInUse,
            cloudAccountRequestEnabled: cloudAccountInUse,
            guestAccountRequestEnable: guestAccountInUse,
          });
        }
      }
    } else {
      setFlags({
        anyAccountInUse: true,
        cloudAccountRequestEnabled: false,
        guestAccountRequestEnable: guestAccountInUse,
      });
    }
  }, [guestAccount, cloudAccount]);

  return (
    <RequestsFlagsContext.Provider value={flags}>
      {flags && props.children}
    </RequestsFlagsContext.Provider>
  );
};
