import { Button, Icon, Td, Tr, Text, Checkbox } from "@avanadeits/vangogh";
import { AccountStatus } from "./AccountStatus";
import { SupportAccountViewResponseItems } from "../../../../services/responses/SupportAccountViewResponseItems";

type SupportAccountTableRowProps = {
  index: number;
  account: SupportAccountViewResponseItems;
};

export const SupportAccountTableRow = ({
  index,
  account,
}: SupportAccountTableRowProps) => {
  return (
    <Tr>
      <Td>{index + 1}.</Td>
      <Td>
        <Text>
          <a href={`mailto:${account.targetEnterpriseId}`}>
            {account.targetEnterpriseId}
          </a>
          <Button
            colorBorder="default"
            size="xxxs"
            title="Copy user name."
            onClick={() => {
              navigator.clipboard.writeText(account.targetEnterpriseId);
            }}
          >
            <Icon name="content_copy" />
          </Button>
        </Text>
      </Td>
      <Td>
        <Text>{account.type}</Text>
      </Td>
      <Td>
        <Text>
          Account:{" "}
          <Checkbox
            elevation="1"
            checked={account.isAvanadeRequestCompleted}
            disabled
          ></Checkbox>{" "}
          Mailbox:{" "}
          <Checkbox
            elevation="1"
            checked={account.isMailboxRequestCompleted}
            disabled
          ></Checkbox>
        </Text>
      </Td>
      <Td>
        <Text>
          <AccountStatus status={account.status} />
        </Text>
      </Td>
      <Td>
        <Text> {account.createdAt} </Text>
      </Td>
    </Tr>
  );
};
