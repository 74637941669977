import { Label, Switcher } from "@avanadeits/vangogh";
import { SearchTextField } from "./SearchTextField";
import { TextField } from "./TextField";

type ManagerSwitcherProps = {
  useAccentureManager: boolean;
  setUseAccentureManager: (value: boolean) => void;
  setFieldValue: (field: string, value: string) => void;
  errors: any;
};

const ManagerSwitcher = ({
  useAccentureManager,
  setUseAccentureManager,
  setFieldValue,
  errors,
}: ManagerSwitcherProps) => (
  <div>
    <Label as={"div"} colorOutline={"default"}>
      {useAccentureManager ? (
        <TextField
          errors={errors}
          id="avanadeMangerEnterpriseId"
          isDisable={!useAccentureManager}
          labelText="2. Please enter Accenture Project Manager/Sponsor of this request (Manager and Above)"
          type="text"
        />
      ) : (
        <SearchTextField
          id="avanadeMangerEnterpriseId"
          labelText="2. Please select Avanade Project Manager/Sponsor of this request (Manager and Above)"
          type="text"
          errors={errors}
          isDisable={false}
          onInputChange={(value) => {
            setFieldValue("avanadeMangerEnterpriseId", value);
          }}
          setFieldValue
        />
      )}
      <br />
      <Switcher
        labelForChecked="Switch to use Avanade Manager"
        labelForUnchecked="Switch to use Accenture Manager"
        onClick={() => {
          setUseAccentureManager(!useAccentureManager);
          setFieldValue("avanadeMangerEnterpriseId", "");
        }}
        size="m"
        checked={useAccentureManager}
      />
    </Label>
  </div>
);

export default ManagerSwitcher;
