import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@avanadeits/vangogh";
import { AvanadeRequest } from "../../../../models/AvanadeRequest";
import { MailboxRequestResponseItems } from "../../../../services/responses/MailboxRequestResponseItems";
import { TextField } from "../../../source-users/requests-forms/Common/TextField";
import { OnPremiseRequestResponseItems } from "../../../../services/onpremiseRequestsService";

type RejectRequestFormProps = {
  handleReject: (x: { reason: string }) => void;
};

export const RejectRequestForm = (props: RejectRequestFormProps) => {
  const initialValue = {
    reason: "",
  };

  const schema = Yup.object().shape({
    reason: Yup.string().required("Reason cannot be empty"),
  });

  return (
    <Formik
      validateOnChange
      validationSchema={schema}
      initialValues={initialValue}
      onSubmit={props.handleReject}
    >
      {({ errors }) => (
        <Form>
          <Button
            icon="contract_delete"
            colorBackground="error-filled"
            type="submit"
          >
            Reject request
          </Button>
          <br />
          <br />
          <TextField
            type="text"
            errors={errors}
            labelText="Reason of rejecting*"
            id="reason"
            isDisable={false}
          />
        </Form>
      )}
    </Formik>
  );
};
