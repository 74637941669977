import React, { useState } from "react";
import { Loader } from "@avanadeits/vangogh";
import { useNavigate } from "react-router-dom";
import { useAzureBasicUserInfoContext } from "../../contexts/AzureBasicUserINdoContext/AzureBasicUserINdoContext";

import mailboxRequestsService from "../../services/mailboxRequestsService";
import toast from "react-hot-toast";
import { FormStatus } from "../../features/source-users/requests-forms/Common/FormStatus";
import { MailboxRequestForm } from "../../features/source-users/requests-forms/Mailbox/MailboxRequestForm";
import { MailboxRequestFormModel } from "../../features/source-users/requests-forms/Mailbox/MailboxRequestFormModel";
import { mailboxValidationSchema } from "../../features/source-users/requests-forms/Mailbox/mailboxValidationSchema";
import { RequestTypeInfo } from "../../features/source-users/requests-forms/Common/RequestTypeInfo";

const MailboxRequestFormPage: React.FC = () => {
  const navigate = useNavigate();
  const basicUserAzureInfo = useAzureBasicUserInfoContext();
  const [submitStatus, setSubmitStatus] = useState<FormStatus>("Idle");

  const initialValue: MailboxRequestFormModel = {
    accentureEmail: basicUserAzureInfo.upn,
    avanadeMangerEnterpriseId: "",
    attachment: undefined,
    id: 0,
    project: { value: "", label: "" },
    projectName: "",
    accentureAccountNotInUserReason: "",
    wbsCode: "",
    businessReason: "",
    attachmentBusinessException: undefined,
  };

  if (submitStatus === "Submitting") return <Loader />;

  if (submitStatus === "Success") navigate("/");

  if (submitStatus === "Error") navigate("/error");

  const handleSubmit = async (x: MailboxRequestFormModel) => {
    try {
      setSubmitStatus("Submitting");
      await mailboxRequestsService.create(x);
      toast.success("Request submitted.");
      setSubmitStatus("Success");
    } catch (e) {
      setSubmitStatus("Error");
    }
  };

  return (
    <>
      <RequestTypeInfo type="Mailbox and MS Teams" />
      <MailboxRequestForm
        handleSubmit={handleSubmit}
        initialValue={initialValue}
        schema={mailboxValidationSchema}
      />
    </>
  );
};

export default MailboxRequestFormPage;
