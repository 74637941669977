import {
  Badge,
  Button,
  Tr,
  Td,
  Icon,
  Modal,
  Loader,
} from "@avanadeits/vangogh";
import { Text } from "@avanadeits/vangogh";
import accountGovernanceService from "../../../../../../services/accountGovernanceService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { msalInstance } from "../../../../../../auth/authProvider";
import { async } from "rxjs";

type AccountInfo = {
  licence: boolean;
  enterpriseId: string;
  daysToRenew: number;
  accountGuid: string;
  status: "InUse";
  creationDate: string;
  type: "Cloud" | "OnPremise";
};

type Props = {
  row: AccountInfo;
};

export const AccountRow = ({ row }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDisable = () => {
    if (isLoading) {
      return true;
    }

    if (row.daysToRenew <= 30) {
      return false;
    } else {
      return true;
    }
  };

  const navigateToOnPremPasswordReset = async () => {
    const request = {
      scopes: [],
      prompt: "login",
    };
    try {
      await msalInstance.loginPopup(request);
      navigate("/password");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Tr>
      <Td>
        <Text size="xl">
          <b>
            {row.type === "Cloud" ? "Avanade Account" : "On-Premise Account"}
          </b>
        </Text>
        <Badge size="xxs" colorBackground="success-filled">
          <b>IN USE</b>
        </Badge>
      </Td>
      <Td>
        <Text>{row.licence ? "MailBox" : "- - -"}</Text>
      </Td>
      <Td>
        <div className="IP-table-online">
          <Button size="xs" colorBorder="default">
            <Icon name="content_copy" />
          </Button>
          <Text>
            {row.enterpriseId.split("@")[0]}
            <br />@{row.enterpriseId.split("@")[1]}
          </Text>
        </div>
      </Td>
      <Td>
        <Text>{row.creationDate}</Text>
      </Td>
      <Td>
        <div className="IP-table-online">
          <Button
            size="s"
            icon="restart_alt"
            colorText="primary"
            colorBorder="primary"
            disabled={isDisable()}
            onClick={async () => {
              setIsLoading(true);
              await accountGovernanceService.renewAccount(row.accountGuid);
              setIsLoading(false);
              toast.success("Account renewed successfully");
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }}
          >
            Renew
          </Button>
          <Text size="s">- {row.daysToRenew} days left</Text>
          {isLoading && <Loader size="xxxs" />}
        </div>
      </Td>
      <Td>
        <Button
          size="s"
          icon="restart_alt"
          colorText="primary"
          colorBorder="primary"
          onClick={async () => {
            row.type === "Cloud"
              ? navigate("/password")
              : await navigateToOnPremPasswordReset();
          }}
        >
          Reset Password
        </Button>
      </Td>
      <Td>- - -</Td>
      <Td>- - -</Td>
    </Tr>
  );
};
