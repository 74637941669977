import { useState, useEffect } from "react";
import accountGovernanceService from "../../../../services/accountGovernanceService";
import { SupportAccountViewResponse } from "../../../../services/responses/SupportAccountViewResponse";
import { SortClass } from "../../helpers/SortClass";

const useAccountList = () => {
  const [requests, setRequests] = useState<SupportAccountViewResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageCount, setPageCount] = useState<number>(1);
  const [currentPageSize, setPageSize] = useState<number>(10);
  const [searchTerm, setCurrentSearchTerm] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>("createdat");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  useEffect(() => {
    const getData = async (
      searchTerm?: string,
      sortColumn?: string,
      sortOrder?: string
    ) => {
      try {
        setIsLoading(true);

        const response = await accountGovernanceService.getAllAccountsFiltered(
          searchTerm,
          sortColumn,
          sortOrder,
          currentPageSize,
          currentPage
        );
        if (response.items.length === 0) {
          setRequests(response);
          setIsLoading(false);
          return;
        }
        setRequests(response);
        setIsLoading(false);
        setCurrentPage(response.pageNumber || 1);
        setPageCount(response.totalCount || 1);
        setPageSize(response.pageSize);
        setSortColumn(response.sortColumn);
        setSortOrder(response.sortOrder);
      } catch (e) {
        setIsLoading(false);
      }
    };

    getData(searchTerm, sortColumn, sortOrder);
  }, [searchTerm, currentPageSize, currentPage, sortColumn, sortOrder]);

  const handleSortClass = (column: string) => {
    return SortClass(column, sortColumn, sortOrder);
  };

  const handleSort = (column: string) => {
    const newSortOrder =
      sortColumn === column && sortOrder === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber + 1);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    if (newPageSize !== currentPageSize) {
      setPageSize(newPageSize);
    }
    setCurrentPage(1);
  };

  return {
    currentPage,
    currentPageCount,
    currentPageSize,
    isLoading,
    requests,
    searchTerm,
    sortColumn,
    sortOrder,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    handleSortClass,
    setCurrentPage,
    setCurrentSearchTerm,
  };
};

export default useAccountList;
