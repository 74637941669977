import { GuestAccount } from "../../../../../contexts/GuestAccountContext/UserGuestAccountContextProvider";
import { NoGuestAccountRow } from "./Rows/NoGuestAccountRow";
import { GuestAccountRow } from "./Rows/GuestAccountRow";

export const tableRowGuestAccountFactory = (account: GuestAccount) => {
  switch (account.status) {
    case "InUse":
      return (
        <GuestAccountRow
          enterpriseId={account.enterpriseId}
          status={account.status}
        />
      );
    case "None":
      return <NoGuestAccountRow />;
  }
};
