import { Text } from "@avanadeits/vangogh";

export const NoAccessPage = () => {
  return (
    <div className="VG-errorboundry">
      <div className="VG-errorboundry__inner">
        <Text style={{ textAlign: "center" }} className="VG_p-4">
          <img
            src="/assets/permissions.png"
            style={{ height: "13em", marginBottom: "3em" }}
            className="VG_mb-4"
          />
          <h1 className="VG-text-h6 VG_mb-2">
            You are not eligible to use this service.
          </h1>
        </Text>
      </div>
    </div>
  );
};
