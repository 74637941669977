const clientId = process.env.REACT_APP_AZURE_AD_CLIENT_ID;
const authority = process.env.REACT_APP_AZURE_AD_AUTHORITY;
const defaultScope = process.env.REACT_APP_AZURE_AD_SCOPE;

export const environment = () => {
  if (clientId === undefined) {
    throw new Error("REACT_APP_AZURE_AD_CLIENT_ID is undefined");
  }

  if (authority === undefined) {
    throw new Error("REACT_APP_AZURE_AD_AUTHORITY is undefined");
  }

  if (defaultScope === undefined) {
    throw new Error("REACT_APP_AZURE_AD_SCOPE is undefined");
  }

  return {
    ClientID: clientId,
    Authority: authority,
    DefaultScope: defaultScope,
  };
};
