import { useState } from "react";
import { ManagersResponse } from "../../../../services/responses/ManagersResponse";
import { Label, Icon, Text, Switcher } from "@avanadeits/vangogh";
import requestCommonService from "../../../../services/requestCommonService";

type CustomTextFieldProps = {
  id: string;
  labelText: string;
  type: string;
  errors: Record<string, any>;
  isDisable: boolean;
  options?: ManagersResponse[];
  onInputChange?: (value: string) => void;
  setFieldValue: any;
};

export const SearchTextField = ({
  isDisable = false,
  options,
  onInputChange,
  ...props
}: CustomTextFieldProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isQuerying, setIsQuerying] = useState<boolean>(false);
  const [fetchedOptions, setFetchedOptions] = useState<ManagersResponse[]>([]);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const handleInputChange = async (inputValue: string) => {
    setInputValue(inputValue);

    if (inputValue.length >= 3) {
      setIsQuerying(true);

      try {
        const managerIds = await requestCommonService.getManagers(inputValue);

        setFetchedOptions(managerIds);
        setShowErrorMessage(managerIds.length === 0);
      } catch (error) {
        setShowErrorMessage(true);
      } finally {
        setIsQuerying(false);
      }
    } else {
      setFetchedOptions([]);
      setShowErrorMessage(false);
    }
  };

  const selectedValue = props.errors[props.id]?.toString();

  return (
    <div>
      <Label
        as={"div"}
        colorOutline={props.errors[props.id] != null ? "error" : "default"}
      >
        <Label.Inner>
          <Label.Header>
            <Label.HeaderMain>
              <Text>{props.labelText}</Text>
            </Label.HeaderMain>
          </Label.Header>
          <Label.Body>
            <input
              className={"VG-input"}
              disabled={isDisable}
              id={props.id}
              name={props.id}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder={"Find manager... type at least 3 characters"}
              type={props.type}
              value={inputValue}
            />
            {isQuerying && (
              <Text size="s" colorText="warning">
                <Icon size="xs" colorText="warning" name="cached" /> Loading...
              </Text>
            )}
            {!isQuerying && showErrorMessage ? (
              <div>
                <Text size="xs" colorText="error">
                  <Icon size="xxs" colorText="error" name="error" /> Unable to
                  find Avanade manager
                </Text>
              </div>
            ) : (
              fetchedOptions.length > 0 && (
                <select
                  id={props.id}
                  name={props.id}
                  value={inputValue}
                  className="VG-input --for-sponsor"
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                    const selectedOption = fetchedOptions.find(
                      (option) => option.value === e.target.value
                    );
                    if (selectedOption && onInputChange) {
                      onInputChange(selectedOption.value);
                    }
                  }}
                  disabled={isDisable}
                >
                  <option value="">Select manager from list...</option>
                  {Array.isArray(fetchedOptions) && (
                    <>
                      {fetchedOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              )
            )}
          </Label.Body>

          {selectedValue !== inputValue && props.errors[props.id] != null && (
            <div>
              <Text size="xs" colorText="error">
                <Icon size="xxs" colorText="error" name="error" /> Error:{" "}
                {props.errors[props.id]?.toString()}
              </Text>
            </div>
          )}
        </Label.Inner>
      </Label>
    </div>
  );
};
