import axios, { AxiosInstance } from "axios";
import { environment } from "./enviroment";

const api: AxiosInstance = axios.create({
  baseURL: environment().API_URL,
  headers: {
    ContentType: "application/json",
    AccessControlAllowOrigin: environment().PORTAL_URL,
    Accept: "application/json",
  },
});

export const apiLocal: AxiosInstance = axios.create({
  baseURL: environment().API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    AccessControlAllowOrigin: environment().PORTAL_URL,
    Accept: "multipart/form-data",
  },
});

export default api;
