import { Button, Text } from "@avanadeits/vangogh";
import { useNavigate } from "react-router-dom";
import { RequestType } from "./RequestTypeInfo";
import { type } from "os";

type RequestFormHeaderProps = {
  type: RequestType;
  onReset?: any;
};

export const RequestFormHeader = (props: RequestFormHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="IP-create-form__hd">
      <div className="IP-create-form__intro">
        <div className="IP-create-form__identity">
          {props.type === "Mailbox and MS Teams" && (
            <img src="/img/logo/logo-365.png" alt="Microsoft 365" />
          )}
        </div>
        <Text className="IP-create-form__title">
          <span className="VG-text-h4">
            Request for: <b>{props.type}</b>
          </span>
        </Text>
      </div>
      <div className="IP-create-form__action">
        <Button
          colorBorder="default"
          icon="restart_alt"
          onClick={props.onReset}
        >
          Reset
        </Button>
        <Button
          colorBorder="default"
          icon="chevron_left"
          onClick={() => {
            navigate("/");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
