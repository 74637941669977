import { Routes, Route } from "react-router-dom";
import { NoAccessPage } from "../page/source-users/NoAccessPage";

type SourceTenantCrossUserTemplateProps = {
  children: React.ReactNode;
};

const SourceTenantCrossUserTemplate = (
  props: SourceTenantCrossUserTemplateProps
): JSX.Element => {
  return <>{props.children}</>;
};

export const SourceTenantCrossUserAppRoutes = () => {
  return (
    <SourceTenantCrossUserTemplate>
      <Routes>
        <Route path="/" element={<NoAccessPage />} />
      </Routes>
    </SourceTenantCrossUserTemplate>
  );
};
