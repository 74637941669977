import { Label, Loader, Text } from "@avanadeits/vangogh";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import toast from "react-hot-toast";
import { RejectRequestForm } from "../../features/target-users/approve-reject-requests-form/common/RejectRequestForm";
import { ApproveRequestForm } from "../../features/target-users/approve-reject-requests-form/common/ApproveRequestForm";
import { RequestTypeInfo } from "../../features/target-users/approve-reject-requests-form/common/RequestTypeInfo";
import { StaticMailboxRequestForm } from "../../features/target-users/approve-reject-requests-form/mailbox/StaticMailboxRequestForm";
import { useLoadMailboxRequest } from "../../features/target-users/approve-reject-requests-form/mailbox/hooks/useLoadMailboxRequest";
import { MailboxRequestIntro } from "../../features/target-users/approve-reject-requests-form/mailbox/components/MailboxRequestIntro";
import mailboxRequestsService from "../../services/mailboxRequestsService";
import { MailboxRequestResponseItems } from "../../services/responses/MailboxRequestResponseItems";

//TODO: CHECK MERGE

export const ApproveRejectMailboxRequestPage = () => {
  const { requestGuid } = useParams();
  const navigator = useNavigate();
  const { mailboxRequest, isRequestLoading } =
    useLoadMailboxRequest(requestGuid);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleApprove = async () => {
    try {
      setIsSubmitting(true);
      await mailboxRequestsService.approve(mailboxRequest!.guid.value);
      setIsSubmitting(false);
      toast.success("Request approved.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    }
  };

  const handleReject = async (x: { reason: string }) => {
    try {
      setIsSubmitting(true);
      await mailboxRequestsService.reject(
        mailboxRequest?.guid.value!,
        x.reason
      );
      setIsSubmitting(false);
      toast.success("Request rejected.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    }
  };

  if (isRequestLoading) {
    return <Loader />;
  }

  return (
    <>
      <RequestTypeInfo type="Mailbox and MS Teams" />
      <div className="IP-create-form">
        <MailboxRequestIntro {...mailboxRequest} />
        {isSubmitting ? (
          <div className="IP-create-form__loader">
            <Loader size="m" label="Processing..." />
          </div>
        ) : (
          <>
            <StaticMailboxRequestForm mailboxRequest={mailboxRequest!} />
            {mailboxRequest?.userRequestStatus === "Pending" && (
              <div className="IP-create-form__fr --for-approve">
                <RejectRequestForm handleReject={handleReject} />
                <span></span>
                <ApproveRequestForm
                  // mailboxRequest={mailboxRequest!}
                  handleApprove={handleApprove}
                />
              </div>
            )}
            {mailboxRequest?.userRequestStatus === "Rejected" && (
              <Label colorOutline={"default"}>
                <Label.Inner>
                  <Label.Header>
                    <Label.HeaderMain>
                      <Text>Reason of rejecting:</Text>
                    </Label.HeaderMain>
                  </Label.Header>
                  <Label.Body>
                    <Text>"{mailboxRequest?.rejectReason}"</Text>
                  </Label.Body>
                </Label.Inner>
              </Label>
            )}
          </>
        )}
      </div>
    </>
  );
};
