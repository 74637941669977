import { Account } from "../../../../../contexts/CloudAccountContext/account";
import { AccountRow } from "./Rows/AccountRow";
import { DeletedCloudAccountRow } from "./Rows/DeletedCloudAccountRow";
import { DisableCloudAccountRow } from "./Rows/DisableCloudAccountRow";
import { NoAccountRow } from "./Rows/NoAccountRow";
import { TerminatedCloudAccountRow } from "./Rows/TerminatedCloudAccountRow";

export const tableRowAccountFactory = (
  account: Account,
  key: string | number
) => {
  switch (account.status) {
    case "NoAccount":
      return <NoAccountRow />;
    case "InUse":
      return (
        <AccountRow
          key={key}
          row={{
            accountGuid: account.accountGuid,
            status: account.status,
            enterpriseId: account.enterpriseId,
            licence: account.mailboxLicense,
            type: account.type,
            creationDate: new Date(account.creationDate).toDateString(),
            daysToRenew: account.daysToRenew,
          }}
        />
      );
    case "Disabled":
      return (
        <DisableCloudAccountRow
          key={key}
          accountGuid={account.accountGuid}
          enterpriseId={account.enterpriseId}
          status={account.status}
          type={account.type}
        />
      );
    case "Terminated":
      return (
        <TerminatedCloudAccountRow
          key={key}
          enterpriseId={account.enterpriseId}
          status={account.status}
          type={account.type}
        />
      );
    case "Deleted":
      return (
        <DeletedCloudAccountRow
          key={key}
          enterpriseId={account.enterpriseId}
          status={"Deleted"}
          type={account.type}
        />
      );
  }
};
