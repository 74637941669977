import { Routes, Route } from "react-router-dom";
import { AvanadeRequestFormPage } from "../page/source-users/AvanadeRequestFormPage";
import Guidance from "../page/source-users/Guidance";
import MailboxRequestFormPage from "../page/source-users/MailboxRequestFormPage";
import MainPage from "../page/source-users/MainPage";
import { OnPremiseRequestFormPage } from "../page/source-users/OnPremiseRequestFormPage";
import { PasswordResetPage } from "../page/source-users/PasswordResetPage";
import { UserAccountListContextProvider } from "../contexts/CloudAccountContext/UserAccountListContextProvider";
import { UserGuestAccountContextProvider } from "../contexts/GuestAccountContext/UserGuestAccountContextProvider";
import { RequestsFlagsContextProvider } from "../contexts/RequestsFlagsContext/RequestsFlagsContextProvider";
import { UserAccountsDiscoveryContextProvider } from "../contexts/UserAccountDiscoveryContext/UserAccountsDiscoveryContextProvider";

type SourceTenantTemplateProps = {
  children: React.ReactNode;
};

const SourceTenantTemplate = (props: SourceTenantTemplateProps) => {
  return (
    <UserAccountsDiscoveryContextProvider>
      <UserAccountListContextProvider>
        <UserGuestAccountContextProvider>
          <RequestsFlagsContextProvider>
            {props.children}
          </RequestsFlagsContextProvider>
        </UserGuestAccountContextProvider>
      </UserAccountListContextProvider>
    </UserAccountsDiscoveryContextProvider>
  );
};

export const SourceTenantAppRoutes = () => {
  return (
    <SourceTenantTemplate>
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route path="/password" element={<PasswordResetPage />} />
        </Route>
        <Route path="/mailbox-request" element={<MailboxRequestFormPage />} />
        <Route path="/avanade-request" element={<AvanadeRequestFormPage />} />
        <Route
          path="/on-premise-request"
          element={<OnPremiseRequestFormPage />}
        />
        <Route path="/guidance" element={<Guidance />} />
      </Routes>
    </SourceTenantTemplate>
  );
};
