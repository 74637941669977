import AccountRequest from "./AccountRequest";
import { environment } from "./enviroment";

type Props = {
  disabled: boolean;
};

const AvanadeGuestRequest = (props: Props) => {
  const Title: JSX.Element = (
    <span>
      Guest Account <br />
    </span>
  );
  return (
    <AccountRequest
      size={2}
      isActive={!props.disabled}
      notEligible={false}
      to={environment().GUEST_ACCESS_PACKAGE_URL}
      title={Title}
      logo={[
        { alt: "Sharepoint", src: "/img/logo/logo-sharepoint.png" },
        { alt: "Microsoft Teams", src: "/img/logo/logo-teams.png" },
      ]}
      activeRequestLinkIcon="open_in_new"
      shortDescription="External page: https://myaccess.microsoft.com/..."
      description={
        "* Guest Account is only required if you want to provide access to application hosted by Avanade or provide access to existing SharePoint sites. If you want to share and collaborate in Teams, you don't need Guest account, you can invite Accenture user directly in Shared Channel using their email address"
      }
    />
  );
};

export default AvanadeGuestRequest;
