import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMsal } from "@azure/msal-react";
import { environment } from "./enviroment";
import accountDiscoveryService from "../../services/accountDiscoveryService";
import UnknownTenantMessage from "../UnknownTenantMessage";
import unauthorisedAccessValidatorService from "../../services/unauthorisedAccessValidatorService";

export const UserTenantsGatewayContext = createContext<User | null>(null);

type Props = {
  children: ReactNode;
  onContentLoad: () => void;
};

type User =
  | {
      tenant: "SourceTenant";
    }
  | {
      tenant: "SourceTenantCrossUser";
    }
  | {
      tenant: "TargetTenant";
    }
  | {
      tenant: "UnknownTenant";
    };

export const useUserTenantsGatewayContext = (): User => {
  const context = useContext(UserTenantsGatewayContext);

  if (context === null)
    throw new Error("User tenant gateway context can not be null");

  return context;
};

const SourceTenant = environment().SourceTenantId;
const TargetTenant = environment().TargetTenantId;

export const UserTenantsGatewayContextProvider = (props: Props) => {
  const msal = useMsal();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    let verifyCompany = async () => {
      let sourceTenantUser = await accountDiscoveryService.verifyUserCompany();

      if (!sourceTenantUser) setUser({ tenant: "SourceTenantCrossUser" });
      else setUser({ tenant: "SourceTenant" });
    };

    const account = msal.accounts[0];

    if (account.tenantId === SourceTenant) {
      verifyCompany();
    } else if (account.tenantId === TargetTenant) {
      setUser({ tenant: "TargetTenant" });
    } else {
      setUser({ tenant: "UnknownTenant" });
    }
    if (user && user.tenant === "UnknownTenant") {
      accessValidator();
    }
    props.onContentLoad();
  }, [msal.accounts, props, props.onContentLoad]);

  if (user === null) {
    return null;
  }

  if (user.tenant === "UnknownTenant") {
    return <UnknownTenantMessage accountInfo={msal.accounts} />;
  }

  return (
    <UserTenantsGatewayContext.Provider value={user}>
      {user && props.children}
    </UserTenantsGatewayContext.Provider>
  );
};
async function accessValidator() {
  try {
    await unauthorisedAccessValidatorService.sendMessage();
  } catch (error) {
    console.error("Error in accessValidator:", error);
  }
}
