import * as Yup from "yup";
import { MailboxRequestFormModel } from "./MailboxRequestFormModel";

export const mailboxValidationSchema =
  Yup.object<MailboxRequestFormModel>().shape({
    accentureEmail: Yup.string().email().required("Required").label("Email"),
    avanadeMangerEnterpriseId: Yup.string()
      .email()
      .required("Required")
      .label("Manger email"),
    project: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .test("project", "Please select following team", (project) => {
        if (project == null) return false;
        if (project.value == null || project.value == "") return false;
        if (project.label == null || project.label == "") return false;
        return true;
      }),
    projectName: Yup.string().when("project", {
      is: (val: any) => val.value === "project_enter_project_name",
      then(schema) {
        return schema.required("Required").label("Project Name");
      },
    }),
    businessReason: Yup.string().when("project", {
      is: (val: any) => val.value === "project_enter_project_name",
      then(schema) {
        return schema.required("Required").label("Business Reason");
      },
    }),
    wbsCode: Yup.string().when("project", {
      is: (val: any) => val.value === "project_enter_project_name",
      then(schema) {
        return schema.required("Required").label("WBS Code");
      },
    }),
    accentureAccountNotInUserReason: Yup.string().when("project", {
      is: (val: any) => val.value === "project_enter_project_name",
      then(schema) {
        return schema.required("Required").label("Reason");
      },
    }),
    attachment: Yup.mixed()
      .required("Please select a file")
      .test("attachment", "Invalid file format", function (value) {
        if (!value) return false;
        const allowedExtensions = ["png", "pdf"];
        const fileName = (value as File).name.toLowerCase();
        return allowedExtensions.some((ext) => fileName.endsWith(ext));
      }) as Yup.MixedSchema<File | undefined>,

    attachmentBusinessException: Yup.mixed().test(
      "attachmentBusinessException",
      "Invalid file format",
      (value: any) => {
        if (!value) return true;
        const allowedExtensions = ["png", "pdf"];
        const fileName = value.name.toLowerCase();
        return allowedExtensions.some((ext) => fileName.endsWith(ext));
      }
    ),
  });
