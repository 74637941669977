import {
  Configuration,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "./enviroment";

const msalConfig: Configuration = {
  auth: {
    clientId: environment().ClientID,
    authority: environment().Authority,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: [environment().DefaultScope],
  authority: environment().Authority,
};
