import { useEffect, useState } from "react";
import { AvanadeRequest } from "../../../../../models/AvanadeRequest";
import avanadeRequestsService from "../../../../../services/avanadeRequestsService";

export const useLoadAvanadeRequest = (avanadeRequestId?: string) => {
  const [request, setRequest] = useState<AvanadeRequest | null>(null);
  const [isRequestLoading, setRequestIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const getData = async () => {
      if (!avanadeRequestId) throw new Error("Request id is null");

      try {
        setRequestIsLoading(true);
        const request = await avanadeRequestsService.getAvanadeRequestByGuid(
          avanadeRequestId
        );
        setRequestIsLoading(false);
        setRequest(request);
      } catch (e) {
        throw new Error("Something went wrong");
      }
    };
    getData();
  }, []);

  return { request, isRequestLoading };
};
