import AccountRequest, { DisableAccountRequest } from "./AccountRequest";
import { useUserAccountListContext } from "../../../contexts/CloudAccountContext/UserAccountListContextProvider";
import { useEffect, useState } from "react";

const AvanadeAccountRequest = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isEligible, setIsEligible] = useState<boolean>(false);
  const accounts = useUserAccountListContext();

  useEffect(() => {
    if (accounts !== null) {
      const hasActiveCloudAccount = accounts.some(
        (account) =>
          (account.status === "InUse" || account.status === "Disabled") &&
          account.type === "Cloud"
      );

      const hasActiveOnPremAccount = accounts.some(
        (account) =>
          (account.status === "InUse" || account.status === "Disabled") &&
          account.type === "OnPremise"
      );

      setIsActive(!(hasActiveOnPremAccount || hasActiveCloudAccount));

      const isEligibleCloudAccount = accounts.some(
        (account) =>
          (account.status === "InUse" || account.status === "Disabled") &&
          account.type === "Cloud"
      );
      const isEligibleOnPremAccount = accounts.some(
        (account) =>
          (account.status === "InUse" || account.status === "Disabled") &&
          account.type === "OnPremise"
      );
      setIsEligible(
        (isEligibleCloudAccount || isEligibleOnPremAccount) &&
          !isEligibleCloudAccount
      );
    }
  }, [accounts]);

  const Title: JSX.Element = (
    <span>
      Avanade
      <br />
      Account
    </span>
  );
  return (
    <AccountRequest
      size={1}
      isActive={isActive}
      notEligible={isEligible}
      to="/avanade-request"
      title={Title}
      logo={"Empty"}
      activeRequestLinkIcon="arrow_forward_ios"
      shortDescription="Go to form."
    />
    // <DisableAccountRequest title={Title} />
  );
};

export default AvanadeAccountRequest;
