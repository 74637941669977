import mailboxRequestsService from "../../../../services/mailboxRequestsService";
import { downloadFile } from "../../helpers/downloadFile";
import { MailboxRequestResponseItems } from "../../../../services/responses/MailboxRequestResponseItems";
import { TFileAction } from "../../helpers/fileActions";
import { AttachmentTypes } from "../../../../models/AttachmentType";
import { StaticInputField } from "../common/fields/StaticInputField";
import { AttachmentStaticField } from "../common/fields/AttachmentStaticField";
import { BusinessExceptionStaticField } from "../common/fields/BusinessExceptionStaticField";
import { TextareaStaticField } from "../common/fields/TextareaStaticField";

type Props = {
  mailboxRequest: MailboxRequestResponseItems;
};

export const StaticMailboxRequestForm = (props: Props) => {
  const { mailboxRequest: request } = props;

  const handleDownload = async (
    attachmentName: string,
    attachmentType: AttachmentTypes,
    action?: TFileAction
  ) => {
    if (!request) throw new Error("Request id is null");

    var attachment =
      await mailboxRequestsService.downloadMailboxRequestAttachment(
        request.guid.value,
        attachmentType
      );
    downloadFile(attachment, action, attachmentName);
  };

  return (
    <>
      <div className="IP-create-form__bd">
        <StaticInputField
          label="1. Please enter your Accenture email address. *"
          value={request?.sourceEmail}
        />
        <StaticInputField
          label="2. Please select Avanade Project Manager/Sponsor of this
          request (Manager and Above). *"
          value={request?.managerEmail}
        />
        <AttachmentStaticField
          label="3. Attach email approval from Avanade Project Manager or
                  Sponsor (Attachment). *"
          value={request?.attachmentName}
          handleDownloadAttachment={handleDownload}
        />
        <StaticInputField
          label="4. Are you part of following team?"
          value={request?.projectParticipant}
        />
      </div>
      <div className="IP-create-form__bd">
        <StaticInputField
          label="4a. Project Name"
          value={request?.projectName!}
        />
        <BusinessExceptionStaticField
          label="4b. Why Accenture Mailbox cannot be used?"
          value={request?.mailboxReason!}
          attachmentBusinessExceptionName={
            request?.attachmentBusinessExceptionIncomingName || ""
          }
          handleDownloadAttachment={handleDownload}
        />
        <TextareaStaticField
          label="4c. Please provide business need for Avanade mailbox."
          value={request?.mailboxBusinessNeed!}
        />
        <StaticInputField
          label="4d. Please Provide WBS Code."
          value={request?.wbsCode!}
        />
      </div>
    </>
  );
};
