import React from "react";
import { Accordion, AccordionHeader, Text } from "@avanadeits/vangogh";
import { Outlet } from "react-router-dom";

const Guidance: React.FC = () => {
  return (
    <div className="IP-guidance">
      <div className="IP-guidance-menu">
        <div className="IP-guidance-menu__inner">
          <Text className="IP-guidance-menu__hd">
            <b>Menu:</b>
          </Text>
          <nav className="IP-guidance-menu__nav">
            <Text>
              <a href="#intro">User Guidance and Support</a>
              <a href="#Submit">1. Submit your request</a>
              <a href="#Renew">2. Renew your account</a>
              <a href="#Status">3. View your status</a>
              <a href="#Manage">4. Managing your Avanade password</a>
              <a href="#Help">5. Get Help</a>
              <a href="#FAQ">6. Frequently Asked Questions</a>
            </Text>
          </nav>
        </div>
      </div>
      <div className="IP-guidance-bd">
        <Text>
          <h1 className="VG-text-h5" id="intro">
            User Guidance and Support:
          </h1>{" "}
          <p>
            Hello, Accenture user, myid.avanade.com is a self-service portal for
            Accenture employees to request an Avanade account, a mailbox, a
            guest account, and other actions like view their account status, and
            reset their Avanade password. Use your Accenture.com account to
            access myid.avanade.com portal and utilize this guidance to learn
            how to use and navigate the portal.
          </p>
          <p>
            There are 3 different types of Avanade account that Accenture
            employees can request:
          </p>
          <ul>
            <li>
              If you are an Accenture employee working on an Avanade project and
              require a mailbox, or Microsoft Teams, request an Avanade account
              with mailbox.
            </li>
            <li>
              If you need an Avanade account for audit purposes or to access
              Avanade Dynamic 365, request an Avanade Account.
            </li>
            <li>
              To collaborate on Teams and share files, you can create a{" "}
              <a
                target="_blank"
                href="https://in.accenture.com/microsoftteams/teams-connect-and-shared-channels/"
              >
                Teams Shared Channels
              </a>
              .
            </li>
            <li>
              To access documents from Avanade SharePoint, you can request an
              Avanade Guest account. Select Guest account (Avanade SharePoint
              access and collaborate in Microsoft Teams).
            </li>
          </ul>
          <br />
          <hr></hr>
          <br />
          <h2 className="VG-text-h6" id="Submit">
            1. Submit your request
          </h2>
          <blockquote></blockquote>
          <ol>
            <li>
              <b>
                “I'm an Accenture user in need of an Avanade branded mailbox and
                Teams for Avanade project (this may require WBS), which option
                should I choose?”.
              </b>
              <br />
              <br />
              <p>
                <b>Avanade Account with Mailbox</b>
              </p>
              <p>
                This option is only required if Accenture user wants to use
                Avanade email ID and Teams for Avanade branded project. Please
                make sure you have a good business reason to request it before
                you make the request. Generally, you don't need an Avanade
                Mailbox as you can accomplish most of the collaboration with
                Avanade users by using a guest account.
              </p>
              <Accordion>
                <AccordionHeader>
                  <Text>
                    <span className="VG-text-sh1">Show Video</span>
                  </Text>
                </AccordionHeader>
                <div>
                  <video width="320" height="240" controls>
                    <source src="assets/mailboxaccount.mp4" type="video/mp4" />
                  </video>
                </div>
              </Accordion>
            </li>
            <li>
              <p>
                <b>
                  “I'm an Accenture user in need of an Avanade ID to perform
                  audit, access Dynamic 365 access, which option should I
                  choose?”.
                </b>{" "}
                <br />
                <br />
                <b>Avanade Account</b>
              </p>
              <p>
                This option is for Accenture employees working on an Avanade
                project that requires access to Avanade resources, like Dynamic
                365.
              </p>
              <br />
              <b>
                What Should You Expect if you requested an Avanade account &
                mailbox or an Avanade account?
              </b>
              <p>
                Once you have submitted one of these requests, your request will
                be reviewed for accuracy by the Identity team, usually receiving
                approval within 2-3 business days. After your request has been
                approved, you'll receive an email with your new Avanade ID and a
                temporary passcode (TAP) for your account. The email will also
                have instructions on how to set up your password.
              </p>
            </li>
            <li>
              <b>
                " I'm an Accenture user and want to collaborate and share files
                with Avanade colleagues in MS teams, which option should I
                choose? "
              </b>{" "}
              <br />
              <p>
                If you are need to share files and communicate with your
                Accenture colleagues, use a Teams Shared Channels. This feature
                offers the option to share a specific Teams channel without the
                need to request a guest account nor switching Teams tenants.
                Visit the{" "}
                <a
                  target="_blank"
                  href="https://in.accenture.com/microsoftteams/teams-connect-and-shared-channels/"
                >
                  Teams Connect and Shared Channels
                </a>{" "}
                page for more information.
              </p>
              <br />
            </li>
            <li>
              <b>
                " I'm an Accenture user and want to access Avanade SharePoint
                sites, which option should I choose? "
              </b>
              <p>
                <b>
                  Guest account (Avanade SharePoint access and collaborate in MS
                  Teams)
                </b>
              </p>{" "}
              <p>
                If you need to access Avanade SharePoint sites and pages,
                request a guest account. Accenture users will have the ability
                to obtain a guest account to access SharePoint sites and pages,
                and Teams to collaborate with their Avanade colleagues and also
                access any application.
              </p>
              <Accordion>
                <AccordionHeader>
                  <Text>
                    <span className="VG-text-sh1">Show Video</span>
                  </Text>
                </AccordionHeader>
                <div>
                  <video width="320" height="240" controls>
                    <source src="assets/guestaccount.mp4" type="video/mp4" />
                  </video>
                </div>
              </Accordion>
            </li>
          </ol>
          <br />
          <hr></hr>
          <br />
          <h2 className="VG-text-h6" id="Renew">
            2. Renew your account
          </h2>{" "}
          <p>
            The Avanade account will be valid for 6 months and you will get a
            reminder email to renew this account, as long as you still need its
            use. You will be able to renew your account on the 'My Accounts' tab
            on the myid.avanade.com portal, by clicking on the 'Renew' option.{" "}
          </p>
          <p>
            If you don't ask for an extension before your last reminder, the
            account will be disabled.
            <b>
              {" "}
              If you want to keep using your account, make sure to renew it
              before the last reminder goes out.{" "}
            </b>
          </p>
          <Accordion>
            <AccordionHeader>
              <Text>
                <span className="VG-text-sh1">Show Video</span>
              </Text>
            </AccordionHeader>
            <div>
              <video width="320" height="240" controls>
                <source src="assets/renewaccount.mp4" type="video/mp4" />
              </video>
            </div>
          </Accordion>
          <br />
          <hr></hr>
          <br />
          <h2 className="VG-text-h6" id="Status">
            3. View your status
          </h2>{" "}
          <p>
            You can view your account status on the 'My Account' tab on the
            myid.avanade.com portal, with the ability to request an extension or
            disable your account. If you currently have an account and are
            looking to change it, you will not be able to request a different
            one until you remove your current account. Once you have deleted
            your current account, you can request a new account.
          </p>
          <br />
          <hr></hr>
          <br />
          <h2 className="VG-text-h6" id="Manage">
            4. Managing your Avanade password
          </h2>{" "}
          <p>
            The Avanade Password must be reset on the myid.avanade.com portal,
            which can be found by choosing the "Password reset" option on the
            status of your account. You can also choose to become fully
            passwordless on your Avanade account at{" "}
            <a href="https://passwordless.avanade.com" target="_blank">
              passwordless.avanade.com{" "}
            </a>
          </p>{" "}
          <Accordion>
            <AccordionHeader>
              <Text>
                <span className="VG-text-sh1">Show Video</span>
              </Text>
            </AccordionHeader>
            <div>
              <video width="320" height="240" controls>
                <source src="assets/resetpassword.mp4" type="video/mp4" />
              </video>
            </div>
          </Accordion>
          <br />
          <hr></hr>
          <br />
          <h2 className="VG-text-h6" id="Help">
            5. Get Help
          </h2>{" "}
          <p>
            Need help? Feel free to reach out to the{" "}
            <a href="mailto:Help@avanade.com">Global Help Desk</a>, you can also
            reach out by{" "}
            <a href="https://avanade.service-now.com/support?id=support_contact">
              phone
            </a>
            .
          </p>
          <br />
          <hr></hr>
          <br />
          <h2 className="VG-text-h6" id="FAQ">
            6. Frequently Asked Questions
          </h2>{" "}
          <dl>
            <dt>
              Q1:{" "}
              <b>
                "Am I able to seek approval from my Accenture manager who is
                also currently working in Avanade?"
              </b>
            </dt>{" "}
            <dd>
              A1: Approval should come from the Avanade project manager who is
              an Avanade FTE.
            </dd>
            <dt>
              Q2:{" "}
              <b>
                "If my request has been approved, will I receive any email
                notification?"
              </b>
            </dt>{" "}
            <dd>
              A2: You will receive an email on your Accenture email ID
              containing the Avanade EID and Temporary Access Passcode along
              with the guidelines for setting up your password for your Avanade
              account.
            </dd>
            <dt>
              Q3:{" "}
              <b>
                "Will the new model impact Accenture users that already have an
                Avanade account?"
              </b>
            </dt>{" "}
            <dd>A3: There is no action needed for them.</dd>
            <dt>
              Q4:{" "}
              <b>
                "I am logging in to Avanade sites using my Avanade credentials,
                does that mean I can access OWA using these credentials?"
              </b>
            </dt>{" "}
            <dd>
              A4: A separate license is needed for Avanade mailbox. You may
              submit a request for Avanade mailbox from myID.avanade.com and
              make sure you have a valid business justification.
            </dd>
            <dt>
              Q5: <b>"Is it compulsory to request Avanade mailbox?"</b>
            </dt>{" "}
            <dd>
              {" "}
              A5: Generally, you don't need an Avanade Mailbox, you can
              accomplish most of the collaboration with Avanade users by using
              only a guest account.
            </dd>
            <dt>
              Q6:{" "}
              <b>
                "Is it possible to do bulk (multiple) requests for multiple
                Accenture users?"
              </b>
            </dt>{" "}
            <dd>
              A6: Users can only submit requests for their own guest account /
              mailbox.
            </dd>{" "}
            <dt>
              Q7:{" "}
              <b>
                "Who should I contact if I have access issues with my new
                Avanade account?"
              </b>
            </dt>{" "}
            <dd>
              A7: For any assistance, please reach out to the{" "}
              <a href="mailto:Help@avanade.com">Global Help Desk</a>, you can
              also reach out by{" "}
              <a href="https://avanade.service-now.com/support?id=support_contact">
                phone
              </a>
              .
            </dd>
          </dl>
        </Text>
      </div>
      <Outlet />
    </div>
  );
};

export default Guidance;
