import { Icon, Text } from "@avanadeits/vangogh";

const NoDataInfo = () => {
  return (
    <>
      <Text
        className="IP-bd__noneAccountMsg"
        padding="5"
        radius="3"
        colorText="info"
        colorBorder="info"
      >
        <Icon name="info" colorText="info" size="s" /> No records to display
      </Text>
    </>
  );
};

export default NoDataInfo;
