import FileFetchResult from "../../../services/responses/FileFetcherResult";
import { TFileAction } from "./fileActions";

export const downloadFile = async (
  data: FileFetchResult,
  action: TFileAction = "download",
  fileName: string
) => {
  const blobFile = new Blob([data.data], { type: data.fileContent });
  const fileURL = URL.createObjectURL(blobFile);
  const link = document.createElement("a");
  link.download = `${fileName}`;
  link.href = fileURL;

  if (action == "download") {
    link.click();
  } else {
    window.open(fileURL, "_blank");
  }
  link.remove();
};
