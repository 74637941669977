export const SortClass = (
  currnetColumn: string,
  sortColumn: string,
  sortOrder: string
) => {
  let classStack = "--sort ";
  if (currnetColumn === sortColumn) {
    classStack += "--sort-" + sortOrder + " ";
  }
  return classStack;
};
