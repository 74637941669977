const apiUrl = process.env.REACT_APP_API_BASE_URL;
const portalUrl = process.env.REACT_APP_PORTAL_BASE_URL;

export const environment = () => {
  if (apiUrl === undefined) {
    throw new Error("REACT_APP_API_BASE_URL is undefined");
  }

  if (portalUrl === undefined) {
    throw new Error("REACT_APP_PORTAL_BASE_URL is undefined");
  }

  return {
    API_URL: apiUrl,
    PORTAL_URL: portalUrl,
  };
};
