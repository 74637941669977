import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import accountGovernanceService from "../../services/accountGovernanceService";
import { GuestAccountResponse } from "../../services/responses/GuestAccountResponse";

export const useUserGuestAccountContext = (): GuestAccount => {
  const account = useContext(GuestAccountContext);

  if (account === null)
    throw new Error("User guest account context can not be null");

  return account;
};

export type GuestAccount =
  | {
      status: "InUse";
      enterpriseId: string;
    }
  | { status: "None" };

export const GuestAccountContext = createContext<GuestAccount | null>(null);

type Props = {
  children: ReactNode;
};

const mapAccount = (account: GuestAccountResponse): GuestAccount => {
  return account.status === "InUse"
    ? { status: "InUse", enterpriseId: account.enterpriseId }
    : { status: "None" };
};

export const UserGuestAccountContextProvider = (props: Props) => {
  const [account, setAccount] = useState<GuestAccount | null>(null);

  useEffect(() => {
    const loadData = async () => {
      var account = await accountGovernanceService.getUserGuestAccount();

      setAccount(mapAccount(account));
    };
    loadData();
  }, []);

  return (
    <GuestAccountContext.Provider value={account}>
      {account && props.children}
    </GuestAccountContext.Provider>
  );
};
