import { AuthenticationResult } from "@azure/msal-browser";
import { loginRequest, msalInstance } from "../../auth/authProvider";
import { AxiosResponse } from "axios";
import axios, { apiLocal } from "./axios";

export const getToken = async () => {
  const account = msalInstance.getAllAccounts();
  if (!account) {
    throw Error("No active account");
  }

  const token = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account[0],
  });

  return token;
};

const config = (token: AuthenticationResult) => {
  return {
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      "Content-Type": "application/json",
    },
  };
};

async function get<T>(endpoint: string) {
  const token = await getToken();
  const response: AxiosResponse<T> = await axios.get(endpoint, config(token));
  return response;
}

async function post<T>(endpoint: string, data?: any) {
  const token = await getToken();
  const response: AxiosResponse<T> = await axios.post(
    endpoint,
    data,
    config(token)
  );
  return response;
}

const configPostFormData = (token: AuthenticationResult) => {
  return {
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };
};

async function getLocal<T>(endpoint: string) {
  const token = await getToken();
  const response: AxiosResponse<T> = await apiLocal.get(
    endpoint,
    config(token)
  );
  return response;
}

async function postLocal<T>(endpoint: string, data?: any) {
  const token = await getToken();
  const response: AxiosResponse<T> = await apiLocal.post(
    endpoint,
    data,
    config(token)
  );
  return response;
}

async function postFormData<T>(endpoint: string, data?: any) {
  const token = await getToken();
  const response: AxiosResponse<T> = await apiLocal.post(
    endpoint,
    data,
    configPostFormData(token)
  );
  return response;
}

export { get, post, postFormData, getLocal, postLocal };
