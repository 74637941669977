import { Label, Input, Icon, Text } from "@avanadeits/vangogh";
import { FormikErrors, Field, FieldProps } from "formik";

type TextFieldProps = {
  id: string;
  labelText: string;
  type: string;
  errors: FormikErrors<any>;
  isDisable: boolean;
  explanationLabel?: JSX.Element | null;
};

export const TextField = ({ isDisable = false, ...props }: TextFieldProps) => {
  return (
    <Label colorOutline={props.errors[props.id] != null ? "error" : "default"}>
      <Label.Inner>
        <Label.Header>
          <Label.HeaderMain>
            <Text>{props.labelText}</Text>
          </Label.HeaderMain>
        </Label.Header>
        <Label.Body>
          <Field name={props.id}>
            {({ field }: FieldProps) => {
              return (
                <Input
                  id={props.id}
                  name={props.id}
                  type={props.type}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={isDisable}
                />
              );
            }}
          </Field>
        </Label.Body>
      </Label.Inner>
      {props.explanationLabel}
      {props.errors[props.id] != null && (
        <div>
          <Text size="xs" colorText="error">
            <Icon size="xxs" colorText="error" name="error" /> Error:{" "}
            {props.errors[props.id]?.toString()}
          </Text>
        </div>
      )}
    </Label>
  );
};
