import { Badge } from "@avanadeits/vangogh";

type AccountStatusProps = {
  status: string;
};

const backgroundFactory = (status: string) => {
  switch (status) {
    case "InUse":
      return "success-tonal";
    case "Disabled":
      return "warning-tonal";
    case "Deleted":
      return "error-tonal";
    case "Terminated":
      return "error-tonal";
  }
};

const colorTextFactory = (status: string) => {
  switch (status) {
    case "InUse":
      return "success";
    case "Disabled":
      return "warning";
    case "Deleted":
      return "error";
    case "Terminated":
      return "error";
  }
};

export const AccountStatus = (props: AccountStatusProps) => {
  return (
    <Badge
      size="xs"
      colorBackground={backgroundFactory(props.status)}
      colorText={colorTextFactory(props.status)}
    >
      <b>{props.status}</b>
    </Badge>
  );
};
