import { Button, Label, Switcher, Text } from "@avanadeits/vangogh";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FileField } from "../Common/FileField";
import { RequestFormHeader } from "../Common/RequestFormHeader";
import { SelectField } from "../Common/SelectField";
import { TextField } from "../Common/TextField";
import * as Yup from "yup";
import { TextFieldRequest } from "../Common/TextFieldRequest";
import { useState } from "react";
import ManagerSwitcher from "../Common/ManagerSwitcher";
import { OnPremiseRequestFormModel } from "./OnPremiseRequestFormModel";

type Props = {
  initialValue: OnPremiseRequestFormModel;
  schema: Yup.ObjectSchema<any>;
  handleSubmit: (x: OnPremiseRequestFormModel) => void;
};

export const OnPremiseRequestFormForm = ({
  handleSubmit,
  initialValue,
  schema,
}: Props) => {
  const navigate = useNavigate();
  const [useAccentureManager, setUseAccentureManager] = useState(false);
  const shouldEnterProjectDetails = (
    formState: OnPremiseRequestFormModel
  ): boolean => {
    return formState.project.value !== "project_enter_project_name";
  };

  return (
    <Formik
      validateOnChange
      validationSchema={schema}
      initialValues={initialValue}
      onSubmit={handleSubmit}
    >
      {({ errors, isValid, setFieldValue, values }) => (
        <Form>
          <div className="IP-create-form">
            <RequestFormHeader
              type="On-Premise Account"
              onReset={() => {
                navigate(0);
              }}
            />
            <div className="IP-create-form__bd">
              <TextField
                type="text"
                errors={errors}
                labelText="1. Please enter your Accenture email address. *"
                id="accentureEmail"
                isDisable={true}
              />
              <ManagerSwitcher
                useAccentureManager={useAccentureManager}
                setUseAccentureManager={setUseAccentureManager}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <FileField
                id="attachment"
                errors={errors}
                labelText=" 3. Attach email approval from Avanade Project Manager
                            or Sponsor (Attachment). *"
                setFieldValue={setFieldValue}
              />
              <SelectField
                id="project"
                errors={errors}
                labelText="4. Are you part of following team?"
                setFieldValue={setFieldValue}
                options={[
                  {
                    label: "C365 Approver User",
                    value: "c365_approver_user",
                  },
                  {
                    label: "RDS User",
                    value: "rds_user",
                  },
                  {
                    label: "Cognos User",
                    value: "cognos_user",
                  },
                  {
                    label: "SAP User",
                    value: "sap_user",
                  },
                  {
                    label: "On Premise DL",
                    value: "on_premise_dl",
                  },
                  {
                    label: "Legal",
                    value: "Legal",
                  },
                  {
                    label: "Project (Enter Project Name)",
                    value: "project_enter_project_name",
                  },
                ]}
              />
            </div>
            <div className="IP-create-form__bd">
              {!shouldEnterProjectDetails(values) && (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="projectName"
                  errors={errors}
                  labelText="4a. Project Name"
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                  explanationLabel={null}
                />
              )}
              {!shouldEnterProjectDetails(values) && (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="wbsCode"
                  errors={errors}
                  labelText="4b. Please Provide WBS Code."
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                  explanationLabel={
                    <div>
                      <Text size="xs"></Text>
                    </div>
                  }
                />
              )}
            </div>
            <div className="IP-create-form__fr --for-submit">
              <Text size="xs">
                *- field required <br />
              </Text>
              <Button
                size="l"
                icon="done_all"
                colorBackground="primary-filled"
                disabled={!isValid}
                type="submit"
              >
                Send Request
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
