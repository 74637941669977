import { Label, Input, Text, Textarea } from "@avanadeits/vangogh";

type Props = {
  label: string;
  value: string;
};

export const TextareaStaticField = (props: Props) => {
  return (
    <>
      <Label>
        <Label.Inner>
          <Label.Header>
            <Label.HeaderMain>
              <Text>{props.label}</Text>
            </Label.HeaderMain>
          </Label.Header>
          <Label.Body>
            <Textarea value={props.value} disabled={true} />
          </Label.Body>
        </Label.Inner>
      </Label>
    </>
  );
};
