import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { msalInstance } from "../../auth/authProvider";

type AzureBasicUserInfo = {
  displayName: string;
  tenantId: string;
  upn: string;
};

const AzureBasicUserInfoContext = createContext<AzureBasicUserInfo | null>(
  null
);

type Props = {
  children: ReactNode;
};

export const useAzureBasicUserInfoContext = (): AzureBasicUserInfo => {
  const basicInfo = useContext(AzureBasicUserInfoContext);

  if (basicInfo === null)
    throw new Error("Azure Basic User Info context can not be null");

  return basicInfo;
};

export const AzureBasicUserInfoContextProvider = (props: Props) => {
  const [account, setAccount] = useState<AzureBasicUserInfo | null>(null);
  const [accountLoaded, setAccountLoaded] = useState<boolean>(false);

  useEffect(() => {
    const getUserDetails = async () => {
      setAccountLoaded(false);
      const account = msalInstance.getAllAccounts();
      setAccount({
        displayName: account[0].name ? account[0].name : "",
        tenantId: account[0].tenantId,
        upn: account[0].username,
      });
      setAccountLoaded(true);
    };
    getUserDetails();
  }, []);

  return (
    <AzureBasicUserInfoContext.Provider value={account}>
      {accountLoaded && props.children}
    </AzureBasicUserInfoContext.Provider>
  );
};
