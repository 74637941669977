import {
  Label,
  Textarea,
  Group,
  Input,
  Loader,
  Button,
  Text,
} from "@avanadeits/vangogh";
import { useState } from "react";
import { AttachmentTypes } from "../../../../../models/AttachmentType";
import { TFileAction } from "../../../helpers/fileActions";

type BusinessExceptionStaticFieldProps = {
  label: string;
  value: string;
  attachmentBusinessExceptionName: string | null;
  handleDownloadAttachment: (
    attachmentName: string,
    attachmentType: AttachmentTypes,
    action?: TFileAction
  ) => void;
};

export const BusinessExceptionStaticField = (
  props: BusinessExceptionStaticFieldProps
) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [isBusinessExceptionAttached] = useState<boolean>(
    props.attachmentBusinessExceptionName ? true : false
  );

  const handleDownload = (action: TFileAction) => {
    setIsDownloading(true);
    props.handleDownloadAttachment(
      props.attachmentBusinessExceptionName || "",
      AttachmentTypes.BusinessException,
      action
    );
    setIsDownloading(false);
  };

  return (
    <>
      <Label>
        <Label.Inner>
          <Label.Header>
            <Label.HeaderMain>
              <Text>{props.label}</Text>
            </Label.HeaderMain>
          </Label.Header>
          <Label.Body>
            <Textarea disabled value={props.value} />
          </Label.Body>
        </Label.Inner>
        <Group>
          <Input
            value={props.attachmentBusinessExceptionName || ""}
            disabled={true}
          />
          {isDownloading ? (
            <Loader size="xxs" />
          ) : (
            <>
              <Button
                icon="download"
                colorBorder="primary"
                colorText="primary"
                title="Download attachment."
                onClick={() => handleDownload("download")}
                disabled={!isBusinessExceptionAttached}
              />
              <Button
                icon="visibility"
                colorBorder="primary"
                colorText="primary"
                title="Open attachment in new window."
                onClick={() => handleDownload("preview")}
                disabled={!isBusinessExceptionAttached}
              />
            </>
          )}
        </Group>
      </Label>
    </>
  );
};
