import { Table, Thead, Tr, Th, Tbody } from "@avanadeits/vangogh";
import { SupportAccountViewResponse } from "../../../services/responses/SupportAccountViewResponse";
import { SupportAccountTableRow } from "./components/SupportAccountTableRow";

type SupportAccountViewTableProps = {
  response?: SupportAccountViewResponse;
  firstNumberIndex: number;
  handleSort: (column: string) => void;
  handleSortClass: (column: string) => string;
};

export const SupportAccountViewTable = ({
  response,
  firstNumberIndex,
  handleSort,
  handleSortClass,
}: SupportAccountViewTableProps) => {
  const items = response?.items;

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th
            className={handleSortClass("sourcetenantenterpriseid")}
            onClick={() => handleSort("sourcetenantenterpriseid")}
          >
            Enterprise ID
          </Th>
          <Th
            className={handleSortClass("type")}
            onClick={() => handleSort("type")}
          >
            Type
          </Th>
          <Th>Request type</Th>
          <Th
            className={handleSortClass("status")}
            onClick={() => handleSort("status")}
          >
            Status
          </Th>
          <Th
            className={handleSortClass("createdat")}
            onClick={() => handleSort("createdat")}
          >
            Creation
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {items
          ?.sort((a, b) => {
            const sortOrder = response?.sortOrder || "asc";

            if (response?.sortColumn) {
              const aValue = String(a[response.sortColumn as keyof typeof a]);
              const bValue = String(b[response.sortColumn as keyof typeof b]);

              return sortOrder === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
            }

            return 0;
          })
          .map((item, index) => (
            <SupportAccountTableRow
              key={`${item.guid}-SupportAccountTableRow`}
              account={item}
              index={firstNumberIndex + index}
            />
          ))}
      </Tbody>
    </Table>
  );
};
