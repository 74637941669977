import { ThemeSwitcher, Header as VGHeader } from "@avanadeits/vangogh";
import HeaderItem from "./HeaderItem";
import Logo from "./Logo";
import { useUserTenantsGatewayContext } from "../contexts/UserTenantsGatewayContext/UserTenantsGatewayContextProvider";

const TargetTemplate = (): JSX.Element => {
  const user = useUserTenantsGatewayContext();

  return user.tenant === "TargetTenant" ? (
    <>
      <HeaderItem to="/" title="Home" />
      <HeaderItem to="mailbox-requests" title="Mailbox Requests" />
      <HeaderItem to="avanade-requests" title="Account Requests" />
      <HeaderItem to="on-premise-requests" title="OnPremise Requests" />
      <HeaderItem to="deletion-requests" title="Deletion Requests" />
    </>
  ) : (
    <></>
  );
};

const SourceTemplate = (): JSX.Element => {
  const user = useUserTenantsGatewayContext();

  return user.tenant === "SourceTenant" ? (
    <>
      <HeaderItem to="/" title="My Accounts" />
      <HeaderItem to="guidance" title="Guidance/Help" />
    </>
  ) : (
    <></>
  );
};

const Header = () => {
  return (
    <VGHeader logo={<Logo />}>
      <VGHeader.Nav>
        <SourceTemplate />
        <TargetTemplate />
      </VGHeader.Nav>
      <VGHeader.Nav.NavUser>
        <ThemeSwitcher size="xs" />
      </VGHeader.Nav.NavUser>
    </VGHeader>
  );
};

export default Header;
