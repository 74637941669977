import { Label, Group, Input, Loader, Button, Text } from "@avanadeits/vangogh";
import { useState } from "react";
import { AttachmentTypes } from "../../../../../models/AttachmentType";
import { TFileAction } from "../../../helpers/fileActions";

type Props = {
  label: string;
  value: string;
  handleDownloadAttachment: (
    attachmentName: string,
    attachmentType: AttachmentTypes,
    action?: TFileAction
  ) => void;
};

export const AttachmentStaticField = (props: Props) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadAttachment = (action: TFileAction) => {
    setIsDownloading(true);
    props.handleDownloadAttachment(
      props.value,
      AttachmentTypes.ManagerApproval,
      action
    );
    setIsDownloading(false);
  };

  return (
    <div>
      <Label>
        <Label.Inner>
          <Label.Header>
            <Label.HeaderMain>
              <Text>{props.label}</Text>
            </Label.HeaderMain>
          </Label.Header>
          <Label.Body>
            <Group>
              <Input value={props.value} disabled={true} />
              {isDownloading ? (
                <Loader size="xxs" />
              ) : (
                <>
                  <Button
                    icon="download"
                    colorBorder="primary"
                    colorText="primary"
                    title="Download attachment."
                    onClick={() => downloadAttachment("download")}
                  />
                  <Button
                    icon="visibility"
                    colorBorder="primary"
                    colorText="primary"
                    title="Open attachment in new window."
                    onClick={() => downloadAttachment("preview")}
                  />
                </>
              )}
            </Group>
          </Label.Body>
        </Label.Inner>
      </Label>
    </div>
  );
};
