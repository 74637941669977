import { Tr, Td, Text, Button, Icon, Badge } from "@avanadeits/vangogh";
import { useNavigate } from "react-router-dom";
import { MailboxRequestResponse } from "../../../services/responses/MailboxRequestResponse";
import { getNumberOfDays } from "../../../features/utilities/getNumberOfDay";

type MailboxRequestViewTableRowProps = {
  item: MailboxRequestResponse["items"][number];
  index: number;
};

const MailboxRequestViewTableRow = ({
  item,
  index,
}: MailboxRequestViewTableRowProps) => {
  const navigate = useNavigate();

  return (
    <Tr>
      <Td>{index + 1}.</Td>
      <Td>
        <Text>
          <a href={`mailto:${item.sourceEmail}`}>{item.sourceEmail}</a>{" "}
          <Button
            colorBorder="default"
            size="xxxs"
            title="Copy user name."
            onClick={() => {
              navigator.clipboard.writeText(item.sourceEmail);
            }}
          >
            <Icon name="content_copy" />
          </Button>
        </Text>
      </Td>
      <Td>
        {item.userRequestStatus === "Pending" && (
          <Badge
            colorBackground="warning-tonal"
            colorBorder="warning"
            colorText="warning"
            size="xxs"
          >
            Pending
          </Badge>
        )}
        {item.userRequestStatus === "Rejected" && (
          <Badge
            colorBackground="error-tonal"
            colorBorder="error"
            colorText="error"
            size="xxs"
          >
            Rejected
          </Badge>
        )}
        {item.userRequestStatus === "Completed" && (
          <Badge
            colorBackground="success-tonal"
            colorBorder="success"
            colorText="success"
            size="xxs"
          >
            Completed
          </Badge>
        )}
        {item.userRequestStatus === "Approved" && (
          <Badge
            colorBackground="success-tonal"
            colorBorder="success"
            colorText="success"
            size="xxs"
          >
            Approved
          </Badge>
        )}
      </Td>
      <Td>
        <Text
          title={`${new Date(item.requestDate).toLocaleTimeString(
            "it-IT"
          )} - ${getNumberOfDays(item.requestDate)} days ago.`}
        >
          {" "}
          {new Date(item.requestDate).toLocaleDateString()}{" "}
          <Icon size="xxs" name="help" className="IP-ico-help" />
        </Text>
      </Td>
      <Td>
        <Button
          size="xs"
          colorText="primary"
          colorBorder="primary"
          icon="menu_open"
          onClick={() => {
            navigate(`/mailbox-requests/${item.guid.value}`);
          }}
        >
          Show request details
        </Button>
      </Td>
    </Tr>
  );
};

export default MailboxRequestViewTableRow;
