import { Tr, Td, Badge, Button, Icon, Text, Loader } from "@avanadeits/vangogh";
import accountGovernanceService from "../../../../../../services/accountGovernanceService";
import { useState } from "react";
import toast from "react-hot-toast";

type Props = {
  status: "Terminated";
  enterpriseId: string;
  type: "Cloud" | "OnPremise";
};

export const TerminatedCloudAccountRow = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Tr>
      <Td>
        <Text size="xl">
          <b>
            {props.type === "Cloud" ? "Avanade Account" : "On-Premise Account"}
          </b>
        </Text>
        <Badge size="xxs" colorBackground="error-filled">
          <b>{props.status.toUpperCase()}</b>
        </Badge>
      </Td>
      <Td>
        <Text>- - -</Text>
      </Td>
      <Td>
        <div className="IP-table-online">
          <Button
            size="xs"
            colorBorder="default"
            onClick={() => {
              navigator.clipboard.writeText(props.enterpriseId);
            }}
          >
            <Icon name="content_copy" />
          </Button>
          <Text>
            {props.enterpriseId.split("@")[0]}
            <br />@{props.enterpriseId.split("@")[1]}
          </Text>
        </div>
      </Td>
      <Td></Td>
      <Td>
        <div className="IP-table-online">
          {isLoading && <Loader size="xxxs" />}
        </div>
      </Td>
      <Td>- - -</Td>
      <Td>- - -</Td>
      <Td>- - -</Td>
    </Tr>
  );
};
