import { Text } from "@avanadeits/vangogh";

import AvanadeAccountRequest from "./AvanadeAccountRequest";
import AvanadeGuestRequest from "./AvanadeGuestRequest";
import AvanadeMailboxRequest from "./AvanadeMailboxRequest";
import OnPremiseAccountRequest from "./OnPremiseAccountRequest";

type AccountRequestListProps = {
  avanadeGuestAccountInUse: boolean;
};

const AccountRequestList = (props: AccountRequestListProps) => {
  return (
    <>
      <Text className="IP-bd__label">
        <b>Request</b> for accounts in <b>Avanade</b> environment:
      </Text>
      <div className="IP-cardList">
        <AvanadeMailboxRequest />
        <AvanadeAccountRequest />
        <AvanadeGuestRequest disabled={props.avanadeGuestAccountInUse} />
        <OnPremiseAccountRequest />
      </div>
    </>
  );
};

export default AccountRequestList;
