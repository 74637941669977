import ApiRoutes from "./config/ApiRoutes";
import { get } from "./config/get";

const unauthorisedAccessValidatorService = {
  sendMessage: async (): Promise<boolean> => {
    const unauthorisedAccessResponse = await get<{
      verifiedAccount: boolean;
    }>(ApiRoutes.UnauthorizedAccessValidator);

    return unauthorisedAccessResponse.data.verifiedAccount;
  },
};

export default unauthorisedAccessValidatorService;
