import { Loader } from "@avanadeits/vangogh";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAzureBasicUserInfoContext } from "../../contexts/AzureBasicUserINdoContext/AzureBasicUserINdoContext";

//TODO: refactor this, but why I am getting error when switching to onpremise vs onPremise
import onPremiseRequestsService from "../../services/onpremiseRequestsService";
import { RequestTypeInfo } from "../../features/source-users/requests-forms/Common/RequestTypeInfo";
import { OnPremiseRequestFormModel } from "../../features/source-users/requests-forms/OnPremise/OnPremiseRequestFormModel";
import { OnPremiseRequestFormForm } from "../../features/source-users/requests-forms/OnPremise/OnPremiseRequestFormForm";
import toast from "react-hot-toast";
import { OnPremiseValidationSchema } from "../../features/source-users/requests-forms/OnPremise/onPremiseValidationSchema";
import { FormStatus } from "../../features/source-users/requests-forms/Common/FormStatus";

export const OnPremiseRequestFormPage = () => {
  const navigate = useNavigate();
  const basicUserAzureInfo = useAzureBasicUserInfoContext();
  const [submitStatus, setSubmitStatus] = useState<FormStatus>("Idle");

  const initialValue: OnPremiseRequestFormModel = {
    accentureEmail: basicUserAzureInfo.upn,
    avanadeMangerEnterpriseId: "",
    attachment: undefined,
    id: 0,
    project: { value: "", label: "" },
    projectName: "",
    wbsCode: "",
  };

  useEffect(() => {
    if (submitStatus === "Success") {
      navigate("/");
    } else if (submitStatus === "Error") {
      navigate("/error");
    }
  }, [navigate, submitStatus]);

  if (submitStatus === "Submitting") return <Loader />;

  const handleSubmit = async (x: OnPremiseRequestFormModel) => {
    try {
      setSubmitStatus("Submitting");
      await onPremiseRequestsService.createOnPremiseRequest(x);
      toast.success("Request submitted.");
      setSubmitStatus("Success");
    } catch (e) {
      setSubmitStatus("Error");
    }
  };

  return (
    <>
      <RequestTypeInfo type="On-Premise Account" />
      <OnPremiseRequestFormForm
        handleSubmit={handleSubmit}
        initialValue={initialValue}
        schema={OnPremiseValidationSchema}
      />
    </>
  );
};
