import {
  Text,
  Button,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Loader,
} from "@avanadeits/vangogh";
import { useEffect, useState } from "react";
import { AccountDeletionRequest } from "../../services/responses/AccountDeletionRequest";
import accountGovernanceService from "../../services/accountGovernanceService";

const useAllAccountDeletionRequests = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<Array<AccountDeletionRequest>>([]);
  const [reload, setReload] = useState<boolean>(false);

  const reloadRequests = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const requests =
          await accountGovernanceService.getAccountDeletionRequests();
        setRequests(requests);
        setIsLoading(false);
      } catch (e) {
        throw new Error("Something went wrong");
      }
    };
    getData();
  }, [reload]);

  return { isLoading, requests, reloadRequests };
};

export const AccountDeletionRequestsPage = () => {
  const { isLoading, requests, reloadRequests } =
    useAllAccountDeletionRequests();

  if (isLoading) return <Loader />;

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Enterprise ID:</Th>
            <Th>Creation date:</Th>
            <Th>Delete:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {requests.map((request: AccountDeletionRequest) => {
            return deletionRequestRowFactory(request, reloadRequests);
          })}
        </Tbody>
      </Table>
    </>
  );
};

const deletionRequestRowFactory = (
  row: AccountDeletionRequest,
  reload: () => void
): JSX.Element => {
  if (row.approver == null && row.reason == null)
    return <PendingDeletionRequest row={row} reload={reload} />;
  else {
    return <ApprovedDeletionRequest row={row} />;
  }
};

type PendingDeletionRequestProps = {
  row: AccountDeletionRequest;
  reload: () => void;
};

const PendingDeletionRequest = (props: PendingDeletionRequestProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setIsLoading(true);
    await accountGovernanceService.markDeletionRequestAsDone(props.row.id);
    setIsLoading(false);
    props.reload();
  };

  return (
    <>
      <Tr>
        <Td>
          <Text>
            <a href={`mailto:${props.row.accountName}`}>
              {props.row.accountName}
            </a>{" "}
            <Button colorBorder="default" size="xxxs" title="Copy user name.">
              <Icon name="content_copy" />
            </Button>
          </Text>
        </Td>
        <Td>
          <Text>{new Date(props.row.requestedOn).toLocaleDateString()}</Text>
        </Td>
        <Td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="xs"
              colorBorder="error"
              colorText="error"
              icon="delete"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
            {isLoading && <Loader size="xxxs" />}
          </div>
        </Td>
      </Tr>
    </>
  );
};

type ApprovedDeletionRequestProps = {
  row: AccountDeletionRequest;
};

const ApprovedDeletionRequest = (props: ApprovedDeletionRequestProps) => {
  return (
    <>
      {" "}
      <Tr>
        <Td>
          <Text>
            <a href={`mailto:${props.row.accountName}`}>
              {props.row.accountName}
            </a>{" "}
            <Button colorBorder="default" size="xxxs" title="Copy user name.">
              <Icon name="content_copy" />
            </Button>
          </Text>
        </Td>
        <Td>
          <Text>{new Date(props.row.requestedOn).toLocaleDateString()}</Text>{" "}
        </Td>
        <Td>
          <Text>
            <small>
              <Text>{props.row.reason.split(" ")[0]}</Text> by:
            </small>{" "}
            <a href={`mailto:${props.row.approver}`}>{props.row.approver}</a>{" "}
            <Button colorBorder="default" size="xxxs" title="Copy user name.">
              <Icon name="content_copy" />
            </Button>
          </Text>
        </Td>
      </Tr>{" "}
    </>
  );
};
