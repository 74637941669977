import { Label, Input, Icon, Text } from "@avanadeits/vangogh";
import { FormikErrors, Field, FieldProps } from "formik";
import toast from "react-hot-toast";

type Props = {
  id: string;
  labelText: string;
  errors: FormikErrors<any>;
  setFieldValue: any;
};

export const FileField = (props: Props) => {
  const acceptedFileTypes = [".png", ".pdf"];

  return (
    <Label colorOutline={props.errors[props.id] != null ? "error" : "warning"}>
      <Label.Inner>
        <Label.Header>
          <Label.HeaderMain>
            <Text>{props.labelText}</Text>
          </Label.HeaderMain>
        </Label.Header>
        <Label.Body>
          <Field name={props.id}>
            {({ field }: FieldProps) => {
              return (
                <Input
                  id={props.id}
                  name={props.id}
                  type="file"
                  accept={acceptedFileTypes.join(",")}
                  onChange={(e) => {
                    const selectedFile = e.target.files![0];
                    if (selectedFile) {
                      const fileType = selectedFile.name.slice(
                        ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
                      );
                      if (
                        !acceptedFileTypes.includes(
                          `.${fileType.toLowerCase()}`
                        )
                      ) {
                        props.setFieldValue(props.id, null);
                        toast.error(
                          "Invalid file type. Please upload a .png or .pdf file."
                        );
                      } else {
                        props.setFieldValue(props.id, selectedFile);
                      }
                    } else {
                      if (props.id === "attachmentBusinessException") {
                        toast.success("File was removed.");
                      } else {
                        props.setFieldValue(props.id, null);
                        toast.error("Please select a file.");
                      }
                    }
                  }}
                />
              );
            }}
          </Field>
        </Label.Body>
      </Label.Inner>
      {props.errors[props.id] != null && (
        <div>
          <Text size="xs" colorText="error">
            <Icon size="xxs" colorText="error" name="error" /> Error:{" "}
            {props.errors[props.id]?.toString() +
              ". " +
              "Please upload a .png or .pdf file."}
          </Text>
        </div>
      )}
    </Label>
  );
};
