import { AccountResponse } from "../../services/responses/AccountResponse";
import { daysToRenew } from "./daysToRenew";
import { Account } from "./account";

export const mapAccount = (account: AccountResponse | null): Account => {
  if (account === null)
    return {
      status: "NoAccount",
      type: "NoAccountType",
      mailboxLicense: false,
    };

  let acceptedType: "Cloud" | "OnPremise";
  if (account.type === "Cloud") acceptedType = "Cloud";
  else if (account.type === "OnPremise") acceptedType = "OnPremise";
  else
    return {
      status: "NoAccount",
      type: "NoAccountType",
      mailboxLicense: false,
    };

  switch (account.status) {
    case "InUse": {
      //TODO: this disabled account date can not be null

      const daysToRenewValue = daysToRenew(
        account.currentDate,
        account.disableAccountDate!
      );
      return {
        accountGuid: account.accountGuid,
        status: "InUse",
        type: acceptedType,
        creationDate: account.creationDate,
        enterpriseId: account.enterpriseId,
        mailboxLicense: account.mailboxLicense,
        daysToRenew: daysToRenewValue,
        readyForRenew: daysToRenewValue < 30,
      };
    }
    case "Disabled":
      return {
        accountGuid: account.accountGuid,
        status: "Disabled",
        enterpriseId: account.enterpriseId,
        type: acceptedType,
        mailboxLicense: account.mailboxLicense,
      };
    case "Deleted":
      return {
        status: "Deleted",
        enterpriseId: account.enterpriseId,
        type: acceptedType,
        mailboxLicense: account.mailboxLicense,
      };
    case "Terminated":
      return {
        status: "Terminated",
        enterpriseId: account.enterpriseId,
        type: acceptedType,
        mailboxLicense: account.mailboxLicense,
      };
    default:
      return {
        status: "NoAccount",
        type: "NoAccountType",
        mailboxLicense: false,
      };
  }
};
