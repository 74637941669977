import ApiRoutes from "./config/ApiRoutes";
import { get } from "./config/get";
import { ManagersResponse } from "./responses/ManagersResponse";

const requestCommonService = {
  getManagers: async (managerSearchTerm: string) => {
    const response = await get<ManagersResponse[]>(
      `${ApiRoutes.ManagersList + "/" + managerSearchTerm}`
    );
    return response.data;
  },
};

export default requestCommonService;
