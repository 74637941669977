import { Button, Label, Switcher, Text } from "@avanadeits/vangogh";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FileField } from "../Common/FileField";
import { RequestFormHeader } from "../Common/RequestFormHeader";
import { SelectField } from "../Common/SelectField";
import { TextField } from "../Common/TextField";
import * as Yup from "yup";
import { AvanadeRequestFormModel } from "./AvanadeRequestFormModel";
import { TextFieldRequest } from "../Common/TextFieldRequest";
import { useState } from "react";
import { SearchTextField } from "../Common/SearchTextField";
import ManagerSwitcher from "../Common/ManagerSwitcher";

type Props = {
  initialValue: AvanadeRequestFormModel;
  schema: Yup.ObjectSchema<any>;
  handleSubmit: (x: AvanadeRequestFormModel) => void;
};

export const AvanadeRequestForm = ({
  handleSubmit,
  initialValue,
  schema,
}: Props) => {
  const navigate = useNavigate();
  const [useAccentureManager, setUseAccentureManager] = useState(false);
  const shouldEnterProjectDetails = (
    formState: AvanadeRequestFormModel
  ): boolean => {
    return formState.project.value !== "project_enter_project_name";
  };

  return (
    <Formik
      validateOnChange
      validationSchema={schema}
      initialValues={initialValue}
      onSubmit={handleSubmit}
    >
      {({ errors, isValid, setFieldValue, values }) => (
        <Form>
          <div className="IP-create-form">
            <RequestFormHeader
              type="Avanade Account"
              onReset={() => {
                navigate(0);
              }}
            />
            <div className="IP-create-form__bd">
              <TextField
                type="text"
                errors={errors}
                labelText="1. Please enter your Accenture email address. *"
                id="accentureEmail"
                isDisable={true}
              />
              <ManagerSwitcher
                useAccentureManager={useAccentureManager}
                setUseAccentureManager={setUseAccentureManager}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <FileField
                id="attachment"
                errors={errors}
                labelText=" 3. Attach email approval from Avanade Project Manager
                            or Sponsor (Attachment). *"
                setFieldValue={setFieldValue}
              />
              <SelectField
                id="project"
                errors={errors}
                labelText="4. Are you part of following team?"
                setFieldValue={setFieldValue}
                options={[
                  {
                    label: "IT (CIO)",
                    value: "IT (CIO)",
                  },
                  {
                    label: "IS (CISO)",
                    value: "IS (CISO)",
                  },
                  {
                    label: "Sales Enablement",
                    value: "Sales Enablement",
                  },
                  {
                    label: "Recruitment",
                    value: "Recruitment",
                  },
                  {
                    label: "Managed Service",
                    value: "Managed Service",
                  },
                  {
                    label: "Legal",
                    value: "Legal",
                  },
                  {
                    label: "Project (Enter Project Name)",
                    value: "project_enter_project_name",
                  },
                ]}
              />
            </div>
            <div className="IP-create-form__bd">
              {!shouldEnterProjectDetails(values) && (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="projectName"
                  errors={errors}
                  labelText="4a. Project Name"
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                  explanationLabel={null}
                />
              )}
              {!shouldEnterProjectDetails(values) && (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="accentureAccountNotInUserReason"
                  errors={errors}
                  labelText="4b. Why Accenture or Avanade guest account can't be used? Please paste error or provide description."
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                />
              )}
              {!shouldEnterProjectDetails(values) && (
                <FileField
                  id="attachmentBusinessException"
                  errors={errors}
                  labelText="Upload attachment with error if applicable."
                  setFieldValue={setFieldValue}
                />
              )}
              {!shouldEnterProjectDetails(values) && (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="businessReason"
                  errors={errors}
                  labelText="4c. Please provide specific details on why you need Avanade account with business reason (For e.g. I could not access Dynamic 365 app or I could not access XYZ.avanade.com sites)."
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                />
              )}
              {!shouldEnterProjectDetails(values) && (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="wbsCode"
                  errors={errors}
                  labelText="4d. Please Provide WBS Code."
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                  explanationLabel={
                    <div>
                      <Text size="xs"></Text>
                    </div>
                  }
                />
              )}
            </div>
            <div className="IP-create-form__fr --for-submit">
              <Text size="xs">
                *- field required <br />
              </Text>
              <Button
                size="l"
                icon="done_all"
                colorBackground="primary-filled"
                disabled={!isValid}
                type="submit"
              >
                Send Request
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
