import { Label, Loader, Text } from "@avanadeits/vangogh";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import toast from "react-hot-toast";
import { RequestTypeInfo } from "../../features/target-users/approve-reject-requests-form/common/RequestTypeInfo";
import mailboxRequestsService from "../../services/mailboxRequestsService";
import { MailboxRequestResponseItems } from "../../services/responses/MailboxRequestResponseItems";
import { useLoadOnPremiseRequest } from "../../features/target-users/approve-reject-requests-form/on-premise/useLoadOnPremiseRequest";
import { StaticOnPremiseRequestForm } from "../../features/target-users/approve-reject-requests-form/on-premise/StaticOnPremiseRequestForm";
import { OnPremiseRequestIntro } from "../../features/target-users/approve-reject-requests-form/on-premise/OnPremiseRequestIntro";
import {
  ApproveRequestForm,
  ReApproveRequestForm,
} from "../../features/target-users/approve-reject-requests-form/common/ApproveRequestForm";
import { RejectRequestForm } from "../../features/target-users/approve-reject-requests-form/common/RejectRequestForm";
import onPremiseRequestsService, {
  OnPremiseRequestResponseItems,
} from "../../services/onpremiseRequestsService";

export const ApproveRejectOnPremiseRequestPage = () => {
  const { requestGuid } = useParams();
  const navigator = useNavigate();
  const { onPremiseRequest, isRequestLoading } =
    useLoadOnPremiseRequest(requestGuid);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleApprove = async () => {
    try {
      setIsSubmitting(true);
      await onPremiseRequestsService.approve(onPremiseRequest!.guid.value);
      setIsSubmitting(false);
      toast.success("Request approved.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    }
  };

  const handleReApprove = async () => {
    try {
      setIsSubmitting(true);
      await onPremiseRequestsService.reApprove(onPremiseRequest!.guid.value);
      setIsSubmitting(false);
      toast.success("Request re-approved.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    }
  };

  const handleReject = async (x: { reason: string }) => {
    try {
      setIsSubmitting(true);
      await onPremiseRequestsService.reject(
        onPremiseRequest!.guid.value,
        x.reason
      );
      setIsSubmitting(false);
      toast.success("Request rejected.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong.");
      setTimeout(() => {
        navigator("/");
      }, 1500);
    }
  };

  if (isRequestLoading) {
    return <Loader />;
  }

  return (
    <>
      <RequestTypeInfo type="On-Premise Account" />
      <div className="IP-create-form">
        <OnPremiseRequestIntro {...onPremiseRequest} />
        {isSubmitting ? (
          <div className="IP-create-form__loader">
            <Loader size="m" label="Processing..." />
          </div>
        ) : (
          <>
            <StaticOnPremiseRequestForm onPremiseRequest={onPremiseRequest!} />
            {onPremiseRequest?.userRequestStatus === "Pending" && (
              <div className="IP-create-form__fr --for-approve">
                <RejectRequestForm handleReject={handleReject} />
                <span></span>
                <ApproveRequestForm handleApprove={handleApprove} />
              </div>
            )}
            {onPremiseRequest?.userRequestStatus === "Approved" && (
              <>
                <ReApproveRequestForm handleApprove={handleReApprove} />
              </>
            )}
            {onPremiseRequest?.userRequestStatus === "Rejected" && (
              <Label colorOutline={"default"}>
                <Label.Inner>
                  <Label.Header>
                    <Label.HeaderMain>
                      <Text>Reason of rejecting:</Text>
                    </Label.HeaderMain>
                  </Label.Header>
                  <Label.Body>
                    <Text>"{onPremiseRequest?.rejectReason}"</Text>
                  </Label.Body>
                </Label.Inner>
              </Label>
            )}
          </>
        )}
      </div>
    </>
  );
};
