import { Table, Tbody, Thead, Tr, Th } from "@avanadeits/vangogh";
import { MailboxRequestResponse } from "../../../services/responses/MailboxRequestResponse";
import MailboxRequestViewTableRow from "../mailbox-request-page/MailboxRequestTableRow";
import OnPremiseRequestTableRow from "./OnPremiseRequestTableRow";

type MailboxRequestResponseProps = {
  response?: MailboxRequestResponse;
  firstNumberIndex: number;
  handleSort: (column: string) => void;
  handleSortClass: (column: string) => string;
};
export const OnPremiseRequestViewTable = ({
  response,
  firstNumberIndex,
  handleSort,
  handleSortClass,
}: MailboxRequestResponseProps) => {
  const items = response?.items;

  const sortedItems = items
    ? items.sort((a, b) => {
        const sortOrder = response?.sortOrder || "asc";

        if (response?.sortColumn) {
          const aValue = a[response.sortColumn as keyof typeof a];
          const bValue = b[response.sortColumn as keyof typeof b];

          if (typeof aValue === "string" && typeof bValue === "string") {
            return sortOrder === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }
        }

        return 0;
      })
    : [];
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th
            className={handleSortClass("sourcetenantenterpriseid")}
            onClick={() => handleSort("sourcetenantenterpriseid")}
          >
            Enterprise ID
          </Th>
          <Th>Type:</Th>
          <Th
            className={handleSortClass("createdat")}
            onClick={() => handleSort("createdat")}
          >
            Requested
          </Th>
          <Th>Request details</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortedItems.map((item, index) => (
          <OnPremiseRequestTableRow
            key={`${item.guid.value}-MailboxRequestTableRow`}
            item={item}
            index={firstNumberIndex + index}
          />
        ))}
      </Tbody>
    </Table>
  );
};
