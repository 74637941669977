import { Button, Label, Switcher, Text } from "@avanadeits/vangogh";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FileField } from "../Common/FileField";
import { MailboxRequestFormModel } from "./MailboxRequestFormModel";
import { RequestFormHeader } from "../Common/RequestFormHeader";
import { SelectField } from "../Common/SelectField";
import { TextField } from "../Common/TextField";
import * as Yup from "yup";
import { AvanadeMailboxCostInformation } from "./AvanadeMailboxCostInformation";
import { TextFieldRequest } from "../Common/TextFieldRequest";
import { useState } from "react";
import { SearchTextField } from "../Common/SearchTextField";
import ManagerSwitcher from "../Common/ManagerSwitcher";

type Props = {
  initialValue: MailboxRequestFormModel;
  schema: Yup.ObjectSchema<any>;
  handleSubmit: (x: MailboxRequestFormModel) => void;
};

export const MailboxRequestForm = ({
  handleSubmit,
  initialValue,
  schema,
}: Props) => {
  const navigate = useNavigate();
  const [useAccentureManager, setUseAccentureManager] = useState(false);
  const shouldEnterProjectDetails = (
    formState: MailboxRequestFormModel
  ): boolean => {
    return formState.project.value !== "project_enter_project_name"
      ? true
      : false;
  };

  return (
    <Formik
      validateOnChange
      validationSchema={schema}
      initialValues={initialValue}
      onSubmit={handleSubmit}
    >
      {({ errors, isValid, setFieldValue, values }) => (
        <Form>
          <div className="IP-create-form">
            <RequestFormHeader
              type="Mailbox and MS Teams"
              onReset={() => {
                navigate(0);
              }}
            />
            <div className="IP-create-form__bd">
              <AvanadeMailboxCostInformation />
              <TextField
                type="text"
                errors={errors}
                labelText="1. Please enter your Accenture email address. *"
                id="accentureEmail"
                isDisable={true}
              />
              <ManagerSwitcher
                useAccentureManager={useAccentureManager}
                setUseAccentureManager={setUseAccentureManager}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <FileField
                id="attachment"
                errors={errors}
                labelText=" 3. Attach email approval from Avanade Project Manager
                            or Sponsor (Allowed attachment types: .png or .pdf). *"
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="IP-create-form__bd">
              <SelectField
                id="project"
                errors={errors}
                labelText="4. Which of the following team are you part of?"
                setFieldValue={setFieldValue}
                options={[
                  {
                    label: "IT (CIO)",
                    value: "IT (CIO)",
                  },
                  {
                    label: "IS (CISO)",
                    value: "IS (CISO)",
                  },
                  {
                    label: "Sales Enablement",
                    value: "Sales Enablement",
                  },
                  {
                    label: "Recruitment",
                    value: "Recruitment",
                  },
                  {
                    label: "Managed Service",
                    value: "Managed Service",
                  },
                  {
                    label: "Legal",
                    value: "Legal",
                  },
                  {
                    label: "Project (Enter Project Name)",
                    value: "project_enter_project_name",
                  },
                ]}
              />
              {!shouldEnterProjectDetails(values) ? (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="projectName"
                  errors={errors}
                  labelText="4a. Project Name"
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                  explanationLabel={null}
                />
              ) : (
                <></>
              )}
              {!shouldEnterProjectDetails(values) ? (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="accentureAccountNotInUserReason"
                  errors={errors}
                  labelText="4b. Why your Accenture Mailbox cannot be used?"
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                />
              ) : (
                <></>
              )}
              {!shouldEnterProjectDetails(values) ? (
                <FileField
                  id="attachmentBusinessException"
                  errors={errors}
                  labelText="Upload attachment with error if applicable."
                  setFieldValue={setFieldValue}
                />
              ) : (
                <></>
              )}
              {!shouldEnterProjectDetails(values) ? (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="businessReason"
                  errors={errors}
                  labelText="4c. Please provide a detail business justification to have mailbox in Avanade."
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                />
              ) : (
                <></>
              )}
              {!shouldEnterProjectDetails(values) ? (
                <TextFieldRequest
                  shouldValidate={!shouldEnterProjectDetails(values)}
                  id="wbsCode"
                  errors={errors}
                  labelText="4d. Please Provide WBS Code."
                  isDisable={shouldEnterProjectDetails(values)}
                  type="text"
                  explanationLabel={
                    <div>
                      <Text size="xs">
                        (Mailbox requires office 365 and Security licenses which
                        is cost to Avanade and Accenture, if you have mailbox
                        already from Accenture, please make sure that you have
                        good business reason for Avanade Mailbox)
                      </Text>
                    </div>
                  }
                />
              ) : (
                <></>
              )}
            </div>
            <div className="IP-create-form__fr --for-submit">
              <Text size="xs">
                *- field required <br />
              </Text>
              <Button
                size="l"
                icon="done_all"
                colorBackground="primary-filled"
                disabled={!isValid}
                type="submit"
              >
                Send Request
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
