import {
  Group,
  Icon,
  Input,
  Label,
  Loader,
  Pagination,
} from "@avanadeits/vangogh";
import { canGoForward } from "../../features/common/paginationHelpers/canGoForward";
import { getPageCount } from "../../features/common/paginationHelpers/getPageCount";
import useAccountList from "../../features/target-users/support-account-table/hooks/AccountList.hook";
import { SupportAccountViewTable } from "../../features/target-users/support-account-table/SupportAccountViewTable";
import { getItemNmber } from "../../features/target-users/helpers/getItemNumber";
import NoDataInfo from "../NoDataInfo";

export const AccountListPage = () => {
  const {
    requests,
    isLoading,
    currentPage,
    currentPageCount,
    currentPageSize,
    searchTerm,
    setCurrentPage,
    setCurrentSearchTerm,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    handleSortClass,
  } = useAccountList();

  return (
    <>
      <div className="IP-table-controlers">
        <Label
          label="Search enterprise id:"
          position="left"
          className="VG-search-form"
        >
          <Group>
            <Input
              value={searchTerm}
              onChange={(event) => {
                setCurrentSearchTerm(event.target.value);
                setCurrentPage(1);
              }}
            />
            <Icon name="search" />
          </Group>
        </Label>
        <Pagination
          currentPage={currentPage - 1}
          pageCount={Math.ceil(currentPageCount / currentPageSize)}
          pageSize={currentPageSize}
          setPage={handlePageChange}
          setPageSize={handlePageSizeChange}
          canGoBack={currentPage > 1}
          canGoForward={canGoForward(
            currentPage,
            getPageCount(requests?.pageSize ?? 1, requests?.totalCount ?? 0)
          )}
          size={"xs"}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : requests?.items.length === 0 ? (
        <NoDataInfo />
      ) : (
        <SupportAccountViewTable
          response={requests}
          firstNumberIndex={getItemNmber(currentPage, currentPageSize)}
          handleSort={handleSort}
          handleSortClass={handleSortClass}
        />
      )}
    </>
  );
};
