import { AccountResponseArray } from "../contexts/CloudAccountContext/accountResponseArray";
import ApiRoutes from "./config/ApiRoutes";
import { buildQueryString } from "./config/buildFilteredQuery";
import { get, getLocal, post, postLocal } from "./config/get";
import { AccountDeletionRequest } from "./responses/AccountDeletionRequest";
import { AccountResponse } from "./responses/AccountResponse";
import { AccountResponseList } from "./responses/AccountResponseList";
import { GuestAccountResponse } from "./responses/GuestAccountResponse";
import { SupportAccountViewResponse } from "./responses/SupportAccountViewResponse";
import { UserPasswordResponse } from "./responses/UserPasswordResponse";

const accountGovernanceService = {
  getUserGuestAccount: async (): Promise<GuestAccountResponse> => {
    const accountRequests = await get<GuestAccountResponse>(
      ApiRoutes.UserGuestAccount
    );
    return accountRequests.data;
  },
  getUserAccount: async (): Promise<AccountResponseList | null> => {
    const accountRequests = await get<AccountResponseList | null>(
      ApiRoutes.UserAccount
    );
    return accountRequests.data;
  },
  resetUserPassword: async (guid: string): Promise<UserPasswordResponse> => {
    const passwordRequest = await post<UserPasswordResponse>(
      ApiRoutes.UserPassword + "/" + guid
    );

    return passwordRequest.data;
  },
  reEnableAccount: async (guid: string): Promise<unknown> => {
    const tapRequest = await post(
      ApiRoutes.ReEnableAccount + "/" + guid + "/re-enable"
    );
    return tapRequest.data;
  },

  renewAccount: async (guid: string): Promise<unknown> => {
    const tapRequest = await post(
      ApiRoutes.RenewAccount + "/" + guid + "/renew"
    );
    return tapRequest.data;
  },

  getAccountDeletionRequests: async (): Promise<
    Array<AccountDeletionRequest>
  > => {
    const response = await getLocal<Array<AccountDeletionRequest>>(
      ApiRoutes.DeletionRequests
    );
    return response.data;
  },

  markDeletionRequestAsDone: async (id: number): Promise<unknown> => {
    const response = await postLocal(
      ApiRoutes.ResolveDeletionRequests + "/" + id + "/resolve"
    );
    return response.data;
  },

  getAllAccounts: async (): Promise<Array<SupportAccountViewResponse>> => {
    const response = await get<Array<SupportAccountViewResponse>>(
      ApiRoutes.GetAllAccounts
    );
    return response.data as Array<SupportAccountViewResponse>;
  },

  getAllAccountsFiltered: async (
    searchTerm?: string,
    sortColumn?: string,
    sortOrder?: string,
    pageSize?: number,
    pageNumber?: number
  ) => {
    const queryString = buildQueryString(
      searchTerm,
      sortColumn,
      sortOrder,
      pageSize,
      pageNumber
    );

    const response = await get<SupportAccountViewResponse>(
      ApiRoutes.GetAllAccounts + `?${queryString}`
    );
    return response.data;
  },
};

export default accountGovernanceService;
