const accessPackageUrl = process.env.REACT_APP_GUEST_ACCESS_PACKAGE_URL;

export const environment = () => {
  if (accessPackageUrl === undefined) {
    throw new Error("REACT_APP_GUEST_ACCESS_PACKAGE_URL is undefined");
  }

  return {
    GUEST_ACCESS_PACKAGE_URL: accessPackageUrl,
  };
};
