import { Button, Text } from "@avanadeits/vangogh";
import { useNavigate } from "react-router-dom";
import { RequestType } from "../../../source-users/requests-forms/Common/RequestTypeInfo";

type RequestTypeProps = {
  type: RequestType;
};

export const RequestTypeInfo = (props: RequestTypeProps) => {
  const navigate = useNavigate();

  // const to =
  //   props.type === "Avanade Account"
  //     ? "/avanade-requests"
  //     : "/mailbox-requests";

  const to = () => {
    if (props.type === "Avanade Account") {
      return "/avanade-requests";
    } else if (props.type === "Mailbox and MS Teams") {
      return "/mailbox-requests";
    } else if (props.type === "On-Premise Account") {
      return "/on-premise-requests";
    } else {
      return "/";
    }
  };

  return (
    <div className="IP-pagination">
      <Button
        colorBorder="default"
        icon="chevron_left"
        onClick={() => {
          navigate(to());
        }}
      >
        Return
      </Button>
      <Text>
        Manage / Approval for: <b>{props.type}</b>
      </Text>
    </div>
  );
};
