import { useEffect, useState } from "react";
import { useUserAccountListContext } from "../../../contexts/CloudAccountContext/UserAccountListContextProvider";
import AccountRequest, { DisableAccountRequest } from "./AccountRequest";

const AvanadeMailboxRequest = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isEligible, setIsEligible] = useState<boolean>(false);
  const accounts = useUserAccountListContext();

  useEffect(() => {
    if (accounts !== null) {
      const hasActiveCloudAccount = accounts.some(
        (account) =>
          account.status === "Disabled" ||
          (account.status === "InUse" &&
            account.mailboxLicense === true &&
            account.type === "Cloud")
      );

      const hasInActiveCloudAccount = accounts.some(
        (account) =>
          ((account.status === "InUse" && account.mailboxLicense === false) ||
            account.status === "Deleted" ||
            account.status === "Terminated") &&
          account.type === "Cloud"
      );

      const hasActiveOnPremAccount = accounts.some(
        (account) =>
          account.status === "InUse" &&
          account.mailboxLicense === true &&
          account.type === "OnPremise"
      );

      setIsActive(
        hasActiveCloudAccount ||
          hasActiveOnPremAccount ||
          (hasInActiveCloudAccount && hasActiveOnPremAccount)
      );

      const isEligibleCloudAccount = accounts.some(
        (account) =>
          (account.status === "InUse" || account.status === "Disabled") &&
          account.type === "Cloud" &&
          account.mailboxLicense === false
      );

      const isEligibleOnPremAccount = accounts.some(
        (account) =>
          (account.status === "InUse" || account.status === "Disabled") &&
          account.type === "OnPremise" &&
          account.mailboxLicense === false
      );

      setIsEligible(isEligibleCloudAccount || isEligibleOnPremAccount);
    }
  }, [accounts]);
  const Title: JSX.Element = (
    <span>Avanade account with mailbox and Teams</span>
  );

  return (
    <AccountRequest
      size={1}
      isActive={!isActive}
      notEligible={isEligible}
      to="/mailbox-request"
      title={Title}
      logo={[{ alt: "Microsoft 365", src: "/img/logo/logo-365.png" }]}
      activeRequestLinkIcon="arrow_forward_ios"
      shortDescription="Go to form."
      description={
        "* This request will require further approval and it may require WBSe."
      }
    />
    // <DisableAccountRequest title={Title} />
  );
};

export default AvanadeMailboxRequest;
