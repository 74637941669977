export const daysToRenew = (currentDateInput: Date, disableAccountDateInput: Date): number => {
  const currentDate = new Date(currentDateInput);
  const disableAccountDate = new Date(disableAccountDateInput);

  if (currentDate.getTime() === disableAccountDate.getTime()) {
    return 0;
  }

  if (currentDate.getTime() > disableAccountDate.getTime()) {
    return 0;
  }

  const timeDifference = Math.abs(
    new Date(disableAccountDateInput).getTime() - new Date(currentDateInput).getTime()
  );
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};
