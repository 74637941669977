import { useEffect, useState } from "react";
import { MailboxRequestResponse } from "../../services/responses/MailboxRequestResponse";
import mailboxRequestsService from "../../services/mailboxRequestsService";
import { TMailboxRequestType } from "../../models/MailboxRequestType";
import { SortClass } from "../../features/target-users/helpers/SortClass";
import onPremiseRequestsService from "../../services/onpremiseRequestsService";

const useOnPremiseRequests = () => {
  const [requests, setRequests] = useState<MailboxRequestResponse>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentType, setCurrentType] = useState<TMailboxRequestType>({
    pending: true,
    rejected: false,
    approved: false,
  });
  const [currentPageCount, setPageCount] = useState<number>(1);
  const [currentPageSize, setPageSize] = useState<number>(10);
  const [searchTerm, setCurrentSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<string>("createdat");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  useEffect(() => {
    const getData = async (
      searchTerm?: string,
      sortColumn?: string,
      sortOrder?: string
    ) => {
      try {
        setIsLoading(true);
        const response =
          await onPremiseRequestsService.allOnPremiseRequestsFiltered(
            searchTerm,
            sortColumn,
            sortOrder,
            currentPageSize,
            currentPage,
            currentType
          );
        if (response.items.length === 0) {
          setRequests(response);
          setIsLoading(false);
          return;
        }

        setRequests(response);
        setIsLoading(false);
        setCurrentPage(response.pageNumber || 1);
        setPageCount(response.totalCount || 1);
        setPageSize(response.pageSize);
        setSortColumn(response.sortColumn);
        setSortOrder(response.sortOrder);
      } catch (e) {
        setIsLoading(false);
      }
    };
    getData(searchTerm, sortColumn, sortOrder);
  }, [
    searchTerm,
    currentPageSize,
    currentPage,
    sortColumn,
    sortOrder,
    currentType,
  ]);

  const handleSortClass = (column: string) => {
    return SortClass(column, sortColumn, sortOrder);
  };

  const handleTypeChange = (setType: TMailboxRequestType) => {
    setCurrentType({ ...currentType, ...setType });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber + 1);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    if (newPageSize !== currentPageSize) {
      setPageSize(newPageSize);
    }
    setCurrentPage(1);
  };

  const handleSort = (column: string) => {
    const newSortOrder =
      sortColumn === column && sortOrder === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  return {
    currentPage,
    currentPageCount,
    currentPageSize,
    currentType,
    isLoading,
    requests,
    searchTerm,
    sortColumn,
    sortOrder,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    handleSortClass,
    handleTypeChange,
    setCurrentPage,
    setCurrentSearchTerm,
  };
};
export default useOnPremiseRequests;
