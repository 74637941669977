import { Badge, Text } from "@avanadeits/vangogh";

export const OnPremiseRequestIntro = ({
  userRequestStatus = "",
  approverEnterpriseId = "",
  approvedAt = "",
}) => {
  return (
    <div className="IP-create-form__hd">
      <div className="IP-create-form__intro">
        <div className="IP-create-form__identity">
          <img src="/img/logo/logo-365.png" alt="Microsoft 365" />
        </div>
        <Text className="IP-create-form__title">
          <span className="VG-text-h4">
            Request for: <b>On Premise Account</b>
          </span>
        </Text>
      </div>
      <div className="IP-create-form__approver">
        {userRequestStatus === "Pending" && (
          <Badge
            colorBackground="warning-tonal"
            colorBorder="warning"
            colorText="warning"
            size="s"
          >
            Pending
          </Badge>
        )}
        {userRequestStatus === "Rejected" && (
          <Badge
            colorBackground="error-tonal"
            colorBorder="error"
            colorText="error"
            size="s"
          >
            Rejected
          </Badge>
        )}
        {userRequestStatus === "Completed" && (
          <Badge
            colorBackground="success-tonal"
            colorBorder="success"
            colorText="success"
            size="s"
          >
            Completed
          </Badge>
        )}
        {userRequestStatus === "Approved" && (
          <Badge
            colorBackground="success-tonal"
            colorBorder="success"
            colorText="success"
            size="s"
          >
            Approved
          </Badge>
        )}
        {approverEnterpriseId && (
          <Text as="span">
            by: {approverEnterpriseId}
            <br />
            {approvedAt && (
              <small>{new Date(approvedAt).toLocaleDateString()}</small>
            )}
          </Text>
        )}
      </div>
    </div>
  );
};
