import {
  Label,
  Group,
  Input,
  Icon,
  Pagination,
  Loader,
  Text,
  Checkbox,
} from "@avanadeits/vangogh";

import { canGoForward } from "../../../features/common/paginationHelpers/canGoForward";
import { getPageCount } from "../../../features/common/paginationHelpers/getPageCount";
import NoDataInfo from "../../NoDataInfo";
import { AvanadeRequestViewTable } from "./AvanadeRequestViewTable";
import { getItemNmber } from "../../../features/target-users/helpers/getItemNumber";
import useContentAvanadeRequests from "../../hooks/useAvanadeRequestsPage.hook";

export const AvanadeRequestsPage = () => {
  const {
    currentPage,
    currentPageCount,
    currentPageSize,
    currentSearchTerm,
    currentType,
    isLoading,
    requests,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    handleSortClass,
    handleTypeChange,
    setCurrentPage,
    setCurrentSearchTerm,
  } = useContentAvanadeRequests();
  return (
    <>
      <div className="IP-table-controlers">
        <Label
          label="Search enterprise id:"
          position="left"
          className="VG-search-form"
        >
          <Group>
            <Input
              value={currentSearchTerm}
              onChange={(event) => {
                setCurrentSearchTerm(event.target.value);
                setCurrentPage(1);
              }}
            />
            <Icon name="search" />
          </Group>
        </Label>
        <Pagination
          currentPage={currentPage - 1}
          pageCount={Math.ceil(currentPageCount / currentPageSize)}
          pageSize={currentPageSize}
          setPage={handlePageChange}
          setPageSize={handlePageSizeChange}
          canGoBack={currentPage > 1}
          canGoForward={canGoForward(
            currentPage,
            getPageCount(requests?.pageSize, requests?.totalCount)
          )}
          size={"xs"}
        />
      </div>
      <div className="IP-table-types">
        <Text as={"span"}>Display request with status:</Text>{" "}
        <Label position="right">
          <Label.Inner>
            <Label.Header>
              <Label.HeaderMain>
                <Text as={"span"}>Pending</Text>
              </Label.HeaderMain>
            </Label.Header>
            <Label.Body>
              <Checkbox
                onChange={() =>
                  handleTypeChange({ pending: !currentType.pending })
                }
                size={"s"}
                checked={currentType.pending}
              />
            </Label.Body>
          </Label.Inner>
        </Label>
        <Label position="right">
          <Label.Inner>
            <Label.Header>
              <Label.HeaderMain>
                <Text as={"span"}>Approved/Completed</Text>
              </Label.HeaderMain>
            </Label.Header>
            <Label.Body>
              <Checkbox
                onChange={() =>
                  handleTypeChange({ approved: !currentType.approved })
                }
                size={"s"}
                checked={currentType.approved}
              />
            </Label.Body>
          </Label.Inner>
        </Label>
        <Label position="right">
          <Label.Inner>
            <Label.Header>
              <Label.HeaderMain>
                <Text as={"span"}>Rejected</Text>
              </Label.HeaderMain>
            </Label.Header>
            <Label.Body>
              <Checkbox
                onChange={() =>
                  handleTypeChange({ rejected: !currentType.rejected })
                }
                size={"s"}
                checked={currentType.rejected}
              />
            </Label.Body>
          </Label.Inner>
        </Label>{" "}
      </div>
      {isLoading ? (
        <Loader />
      ) : requests?.items.length === 0 ? (
        <NoDataInfo />
      ) : (
        <>
          <AvanadeRequestViewTable
            response={requests}
            firstNumberIndex={getItemNmber(currentPage, currentPageSize)}
            handleSort={handleSort}
            handleSortClass={handleSortClass}
          />
        </>
      )}
    </>
  );
};
