import { AvanadeRequestFormModel } from "../features/source-users/requests-forms/Avanade/AvanadeRequestFormModel";
import { AttachmentTypes } from "../models/AttachmentType";
import { AvanadeRequest } from "../models/AvanadeRequest";
import { TAvanadeRequestType } from "../models/AvanadeRequestType";

import ApiRoutes from "./config/ApiRoutes";
import { buildQueryString } from "./config/buildFilteredQuery";
import { get, post, postFormData, postLocal } from "./config/get";
import { FileFetcher } from "./config/getFile";
import { AvanadeRequestResponse } from "./responses/AvanadeRequestResponse";
import FileFetchResult from "./responses/FileFetcherResult";
import { ManagersResponse } from "./responses/ManagersResponse";

const avanadeRequestsService = {
  createAvanadeRequest: async (data: AvanadeRequestFormModel) => {
    var request: FormData = new FormData();
    request.append("SourceEmail", data.accentureEmail!);
    request.append("ManagerEmail", data.avanadeMangerEnterpriseId!);
    request.append("ProjectParticipant", data.project.value);
    request.append("ProjectName", data.projectName);
    request.append("MailboxReason", data.accentureAccountNotInUserReason);
    request.append("MailboxBusinessNeed", data.businessReason);
    request.append("WbsCode", data.wbsCode);
    request.append("AttachmentContent", data.attachment!);
    request.append(
      "AttachmentBusinessException",
      data.attachmentBusinessException!
    );

    await postFormData(ApiRoutes.CreateAvanadeRequest, request);
  },

  downloadAvanadeRequestAttachment: async (
    guid: string,
    attachmentType: number
  ) => {
    const queryParams = attachmentType
      ? `?attachmentType=${attachmentType}`
      : "";
    const { data, fileContent } = await FileFetcher<FileFetchResult>(
      ApiRoutes.DownloadAvanadeRequestAttachment + "/" + guid + queryParams,
      {
        method: "GET",
      }
    );

    return { data, fileContent };
  },

  allAvanadeRequestsFiltered: async (
    searchTerm?: string,
    sortColumn?: string,
    sortOrder?: string,
    pageSize?: number,
    pageNumber?: number,
    currentType?: TAvanadeRequestType
  ) => {
    const queryString = buildQueryString(
      searchTerm,
      sortColumn,
      sortOrder,
      pageSize,
      pageNumber,
      currentType
    );
    const response = await get<AvanadeRequestResponse>(
      ApiRoutes.GetAllAvanadeRequests + `?${queryString}`
    );
    return response.data;
  },

  rejectAvanadeRequest: async (guid: string, rejectReason: string) => {
    const response = await postLocal<AvanadeRequest>(
      ApiRoutes.RejectAvanadeRequest + "/" + "reject",
      { requestGuid: guid, rejectReason: rejectReason }
    );
    return response.data;
  },

  approveAvanadeRequest: async (guid: string) => {
    const response = await post(
      ApiRoutes.ApproveAvanadeRequest + "/" + "approve" + "/" + guid
    );
    return response.data;
  },

  getAvanadeRequestByGuid: async (guid: string) => {
    const response = await get<AvanadeRequest>(
      ApiRoutes.GetAvanadeRequestByGuid + "/" + guid
    );
    return response.data;
  },
  getManagers: async (managerId: string) => {
    const response = await post<ManagersResponse[]>(
      `${ApiRoutes.ManagersList + "/" + managerId}`
    );
    return response.data;
  },
};

export default avanadeRequestsService;
