import { TAvanadeRequestType } from "../../models/AvanadeRequestType";

export function buildQueryString(
  searchTerm?: string,
  sortColumn?: string,
  sortOrder?: string,
  pageSize?: number,
  pageNumber?: number,
  currentType?: TAvanadeRequestType
): string {
  const queryParams = [];

  if (searchTerm) {
    queryParams.push(`searchTerm=${encodeURIComponent(searchTerm)}`);
  }

  if (sortColumn) {
    queryParams.push(`sortColumn=${encodeURIComponent(sortColumn)}`);
  }

  if (sortOrder) {
    queryParams.push(`sortOrder=${encodeURIComponent(sortOrder)}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${encodeURIComponent(pageSize.toString())}`);
  }

  if (pageNumber) {
    queryParams.push(`pageNumber=${encodeURIComponent(pageNumber.toString())}`);
  }

  if (currentType) {
    // converting boolean to number
    queryParams.push(`sp=${encodeURIComponent(+ currentType.pending)}`);
    queryParams.push(`sr=${encodeURIComponent(+ currentType.rejected)}`);
    queryParams.push(`sa=${encodeURIComponent(+ currentType.approved)}`);
  }

  return queryParams.join('&');
}