import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.sass";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "@avanadeits/vangogh";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./auth/authProvider";
import { InteractionType } from "@azure/msal-browser";
import { AzureBasicUserInfoContextProvider } from "./contexts/AzureBasicUserINdoContext/AzureBasicUserINdoContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ThemeContextProvider>
          <AzureBasicUserInfoContextProvider>
            <App />
          </AzureBasicUserInfoContextProvider>
        </ThemeContextProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
