import { AccountInfo } from "@azure/msal-browser";

type UnknownTenantMessageProps = {
  accountInfo: AccountInfo[];
};

const UnknownTenantMessage: React.FC<UnknownTenantMessageProps> = ({
  accountInfo,
}) => (
  <div>
    <p
      style={{
        color: "#222222",
        fontSize: "18px",
        textAlign: "center",
      }}
    >
      Hello {accountInfo[0].username || "User"}! We couldn't recognize your
      token <br></br> tenantId : {accountInfo[0].tenantId}
      <br></br> homeAccountId : {accountInfo[0].homeAccountId}
      <br></br> environment : {accountInfo[0].environment}
      <br></br>
      The information about the error was sent to the administrator.
      <br></br>If you think you should have access to that page, please contact
      with us providing print screen with above information
    </p>
  </div>
);

export default UnknownTenantMessage;
