const sourceTenant = process.env.REACT_APP_AZURE_AD_SourceTenant_ID;
const targetTenant = process.env.REACT_APP_AZURE_AD_TargetTenant_ID;

export const environment = () => {
  if (sourceTenant === undefined) {
    throw new Error("REACT_APP_AZURE_AD_SourceTenant_ID is undefined");
  }

  if (sourceTenant === undefined) {
    throw new Error("REACT_APP_AZURE_AD_TargetTenant_ID is undefined");
  }

  return {
    SourceTenantId: sourceTenant,
    TargetTenantId: targetTenant,
  };
};
