import { Text } from "@avanadeits/vangogh";

export const AvanadeMailboxCostInformation = () => {
  return (
    <Text>
      Avanade will need to <b>purchase a Dynamic 365 license</b> on your
      account, which <b>costs Avanade and Accenture</b>.
      <br />
      <br /> <b>Please:</b> make sure you have a good business reason to request
      it before you make the request.
    </Text>
  );
};
