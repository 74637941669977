import { useEffect, useState } from "react";
import mailboxRequestsService from "../../../../../services/mailboxRequestsService";
import { MailboxRequestResponseItems } from "../../../../../services/responses/MailboxRequestResponseItems";

export const useLoadMailboxRequest = (mailboxRequestId?: string) => {
  const [mailboxRequest, setMailboxRequest] =
    useState<MailboxRequestResponseItems | null>(null);
  const [isRequestLoading, setRequestIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const getData = async () => {
      if (!mailboxRequestId) throw new Error("Request id is null");

      try {
        setRequestIsLoading(true);
        const request = await mailboxRequestsService.getByGuid(
          mailboxRequestId
        );
        setRequestIsLoading(false);
        setMailboxRequest(request);
      } catch (e) {
        throw new Error("Something went wrong");
      }
    };
    getData();
  }, []);

  return { mailboxRequest, isRequestLoading };
};
