//TODO: remove this class, this is redundant

enum ApiRoutes {
  UserGuestAccount = "user/account/guest",
  UserAccount = "user/account",
  AccountDiscovery = "user/account/verify",
  CompanyVerification = "user/account/verifyCompany",
  CreateMailboxRequest = "user/mailbox-requests",
  CreateAvanadeRequest = "user/avanade-requests",
  GetMailboxRequestByGuid = "user/mailbox-requests",
  GetAvanadeRequestByGuid = "user/avanade-requests",
  ApproveMailboxRequest = "user/mailbox-requests",
  ApproveAvanadeRequest = "user/avanade-requests",
  RejectMailboxRequest = "user/mailbox-requests",
  RejectAvanadeRequest = "user/avanade-requests",
  GetAllMailboxRequests = "user/mailbox-requests/all",
  GetAllAvanadeRequests = "user/avanade-requests/all",
  UserPassword = "user/account/resetPassword",
  ReEnableAccount = "user/account",
  RenewAccount = "user/account",
  DeletionRequests = "support/account/deletion-requests",
  ResolveDeletionRequests = "support/account/deletion-requests",
  GetAllAccounts = "support/account/all",
  DownloadMailboxRequestAttachment = "user/mailbox-requests/getAttachment",
  DownloadAvanadeRequestAttachment = "user/avanade-requests/getAttachment",
  UnauthorizedAccessValidator = "validateToken",
  ManagersList = "user/common-requests/managers",
  CreateOnPremiseRequest = "user/on-premise-requests",
  GetAllOnPremiseRequests = "user/on-premise-requests/all",
  GetOnPremiseRequestByGuid = "user/on-premise-requests",
}

export default ApiRoutes;
