import ApiRoutes from "./config/ApiRoutes";
import { get, post } from "./config/get";

const accountDiscoveryService = {
  discoverUserCloudAccount: async (): Promise<boolean> => {
    const accountDiscoveryResponse = await post<{ verifiedAccount: boolean }>(
      ApiRoutes.AccountDiscovery
    );

    return accountDiscoveryResponse.data.verifiedAccount;
  },
  verifyUserCompany: async (): Promise<boolean> => {
    const companyVerificationResponse = await get<{
      isSourceTenantCompanyUser: boolean;
    }>(ApiRoutes.CompanyVerification);

    return companyVerificationResponse.data.isSourceTenantCompanyUser;
  },
};
export default accountDiscoveryService;
