import { OnPremiseRequestFormModel } from "../features/source-users/requests-forms/OnPremise/OnPremiseRequestFormModel";
import { TMailboxRequestType } from "../models/MailboxRequestType";
import { FileFetcher } from "./config/getFile";
import FileFetchResult from "./responses/FileFetcherResult";
import ApiRoutes from "./config/ApiRoutes";
import { buildQueryString } from "./config/buildFilteredQuery";
import { get, postFormData, postLocal } from "./config/get";

const onPremiseRequestsService = {
  createOnPremiseRequest: async (data: OnPremiseRequestFormModel) => {
    var request: FormData = new FormData();
    request.append("SourceEmail", data.accentureEmail!);
    request.append("ManagerEmail", data.avanadeMangerEnterpriseId!);
    request.append("ProjectParticipant", data.project.value);
    request.append("ProjectName", data.projectName);
    request.append("WbsCode", data.wbsCode);
    request.append("AttachmentContent", data.attachment!);

    await postFormData(ApiRoutes.CreateOnPremiseRequest, request);
  },
  allOnPremiseRequestsFiltered: async (
    searchTerm?: string,
    sortColumn?: string,
    sortOrder?: string,
    pageSize?: number,
    pageNumber?: number,
    currentType?: TMailboxRequestType
  ) => {
    const queryString = buildQueryString(
      searchTerm,
      sortColumn,
      sortOrder,
      pageSize,
      pageNumber,
      currentType
    );
    //TODO: remove those any
    const response = await get<any>(
      ApiRoutes.GetAllOnPremiseRequests + `?${queryString}`
    );

    return response.data;
  },
  getByGuid: async (guid: string) => {
    const response = await get<OnPremiseRequestResponseItems>(
      ApiRoutes.GetOnPremiseRequestByGuid + "/" + guid
    );
    return response.data;
  },

  approve: async (guid: string) => {
    const response = await postLocal(
      "user/on-premise-requests" + "/" + "approve" + "/" + guid
    );
    return response.data;
  },
  reApprove: async (guid: string) => {
    const response = await postLocal(
      "user/on-premise-requests" + "/" + "re-approve" + "/" + guid
    );
    return response.data;
  },
  reject: async (guid: string, rejectReason: string) => {
    const response = await postLocal<OnPremiseRequestResponseItems>(
      "user/on-premise-requests" + "/" + "reject",
      { requestGuid: guid, rejectReason: rejectReason }
    );
    return response.data;
  },
  downloadMailboxRequestAttachment: async (
    guid: string
  ): Promise<FileFetchResult> => {
    const { data, fileContent } = await FileFetcher<FileFetchResult>(
      "user/on-premise-requests/" + guid + "/attachments/approval",
      {
        method: "GET",
      }
    );

    return { data, fileContent };
  },
};

export default onPremiseRequestsService;

export type OnPremiseRequestResponseItems = {
  attachmentName: string;
  guid: { value: string };
  managerEmail: string;
  projectName: null | string;
  projectParticipant: string;
  sourceEmail: string;
  userRequestStatus: string;
  wbsCode: null | string;
  requestDate: string;
  approverEnterpriseId?: string;
  approvedAt?: string;
  rejectReason?: string;
};
