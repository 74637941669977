import mailboxRequestsService from "../../../../services/mailboxRequestsService";
import { downloadFile } from "../../helpers/downloadFile";
import { MailboxRequestResponseItems } from "../../../../services/responses/MailboxRequestResponseItems";
import { TFileAction } from "../../helpers/fileActions";
import { AttachmentTypes } from "../../../../models/AttachmentType";
import { StaticInputField } from "../common/fields/StaticInputField";
import { AttachmentStaticField } from "../common/fields/AttachmentStaticField";
import { BusinessExceptionStaticField } from "../common/fields/BusinessExceptionStaticField";
import { TextareaStaticField } from "../common/fields/TextareaStaticField";
import onPremiseRequestsService, {
  OnPremiseRequestResponseItems,
} from "../../../../services/onpremiseRequestsService";

type Props = {
  onPremiseRequest: OnPremiseRequestResponseItems;
};

export const StaticOnPremiseRequestForm = (props: Props) => {
  const { onPremiseRequest: request } = props;

  const handleDownload = async (
    attachmentName: string,
    attachmentType: AttachmentTypes,
    action?: TFileAction
  ) => {
    if (!request) throw new Error("Request id is null");

    var attachment =
      await onPremiseRequestsService.downloadMailboxRequestAttachment(
        request.guid.value
      );
    downloadFile(attachment, action, attachmentName);
  };

  return (
    <>
      <div className="IP-create-form__bd">
        <StaticInputField
          label="1. Please enter your Accenture email address. *"
          value={request?.sourceEmail}
        />
        <StaticInputField
          label="2. Please select Avanade Project Manager/Sponsor of this
          request (Manager and Above). *"
          value={request?.managerEmail}
        />
        <AttachmentStaticField
          label="3. Attach email approval from Avanade Project Manager or
                  Sponsor (Attachment). *"
          value={request?.attachmentName}
          handleDownloadAttachment={handleDownload}
        />
        <StaticInputField
          label="4. Are you part of following team?"
          value={request?.projectParticipant}
        />
      </div>
      <div className="IP-create-form__bd">
        <StaticInputField
          label="4a. Project Name"
          value={request?.projectName!}
        />
        <StaticInputField
          label="4b. Please Provide WBS Code."
          value={request?.wbsCode!}
        />
      </div>
    </>
  );
};
