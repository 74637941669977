import { useEffect, useState } from "react";
import onPremiseRequestsService, {
  OnPremiseRequestResponseItems,
} from "../../../../services/onpremiseRequestsService";

export const useLoadOnPremiseRequest = (onPremiseGuid?: string) => {
  const [onPremiseRequest, setOnPremiseRequest] =
    useState<OnPremiseRequestResponseItems | null>(null);
  const [isRequestLoading, setRequestIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const getData = async () => {
      if (!onPremiseGuid) throw new Error("Request id is null");

      try {
        setRequestIsLoading(true);
        const request = await onPremiseRequestsService.getByGuid(onPremiseGuid);
        setRequestIsLoading(false);
        setOnPremiseRequest(request);
      } catch (e) {
        throw new Error("Something went wrong");
      }
    };
    getData();
  }, []);

  return { onPremiseRequest, isRequestLoading };
};
