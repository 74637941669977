import { ReactNode, createContext, useEffect, useState } from "react";
import accountDiscoveryService from "../../services/accountDiscoveryService";

export const UserAccountsDiscoveryContext = createContext(null);

type Props = {
  children: ReactNode;
};

export const UserAccountsDiscoveryContextProvider = (props: Props) => {
  const [accountDiscovered, setAccountDiscovered] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      await accountDiscoveryService.discoverUserCloudAccount();
      setAccountDiscovered(true);
    };
    loadData();
  }, []);

  return (
    <UserAccountsDiscoveryContext.Provider value={null}>
      {accountDiscovered && props.children}
    </UserAccountsDiscoveryContext.Provider>
  );
};
