import { Label, AvaSelect, Icon, Text } from "@avanadeits/vangogh";
import { FormikErrors, Field, FieldProps } from "formik";

type Props = {
  id: string;
  labelText: string;
  errors: FormikErrors<any>;
  setFieldValue: any;
  options: { value: string; label: string }[];
};
export const SelectField = (props: Props) => {
  return (
    <Label colorOutline={props.errors[props.id] != null ? "error" : "default"}>
      <Label.Inner>
        <Label.Header>
          <Label.HeaderMain>
            <Text>{props.labelText}</Text>
          </Label.HeaderMain>
        </Label.Header>
        <Label.Body>
          <Field name={props.id}>
            {({ field }: FieldProps) => {
              return (
                <AvaSelect
                  id={props.id}
                  onChange={(e) => {
                    props.setFieldValue(props.id, e);
                  }}
                  value={field.value}
                  options={props.options}
                />
              );
            }}
          </Field>
        </Label.Body>
      </Label.Inner>
      {props.errors[props.id] != null && (
        <div>
          <Text size="xs" colorText="error">
            <Icon size="xxs" colorText="error" name="error" />
            Error: {props.errors[props.id]?.toString()}
          </Text>
        </div>
      )}
    </Label>
  );
};
