import { Thead, Tr, Th } from "@avanadeits/vangogh";

const AccountTableHeaders = () => {
  return (
    <Thead>
      <Tr>
        <Th>Status:</Th>
        <Th>Licenses:</Th>
        <Th>Enterprise ID:</Th>
        <Th>Creation date:</Th>
        <Th>Renew date:</Th>
        <Th>Password:</Th>
        <Th>Disable:</Th>
        <Th>Delete:</Th>
      </Tr>
    </Thead>
  );
};

export default AccountTableHeaders;
