import { environment } from "./enviroment";
import { getToken } from "./get";

async function addDefaultOptions(options?: RequestInit): Promise<RequestInit> {
  const update: RequestInit = { ...options };
  const token = await getToken();

  update.headers = {
    ...update.headers,
    Authorization: "Bearer " + token.accessToken,
  };

  return update;
}

export interface FileFetchResult {
  data: ArrayBuffer;
  fileContent?: string | any;
}

export async function FileFetcher<T>(
  path: string,
  options?: RequestInit
): Promise<FileFetchResult> {
  try {
    const response = await fetch(
      `${environment().API_URL}/${path}`,
      await addDefaultOptions(options)
    );

    if (!response.ok) {
      const err = await response.json();
      throw new Error(err.Message ? err.Message : "Unexpected error");
    }

    const fileContent = response.headers.get("Content-Type");
    const data = await response.arrayBuffer();

    const result: FileFetchResult = {
      data,
      fileContent,
    };

    if (fileContent !== null && fileContent !== undefined) {
      result.fileContent = fileContent;
    }

    return result;
  } catch (err: any) {
    throw err;
  }
}
