import { Button } from "@avanadeits/vangogh";
import { MailboxRequestResponseItems } from "../../../../services/responses/MailboxRequestResponseItems";

export const ApproveRequestForm = (props: { handleApprove: () => void }) => {
  return (
    <Button
      size="l"
      icon="done_all"
      colorBackground="success-filled"
      onClick={props.handleApprove}
    >
      Approve request
    </Button>
  );
};

export const ReApproveRequestForm = (props: { handleApprove: () => void }) => {
  return (
    <Button
      size="l"
      icon="repeat"
      colorBackground="info-filled"
      onClick={props.handleApprove}
    >
      Re-Approve request
    </Button>
  );
};
