import React, { useContext } from "react";
import { Loader, Text } from "@avanadeits/vangogh";

import { Outlet } from "react-router-dom";
import { GuestAccountContext } from "../../contexts/GuestAccountContext/UserGuestAccountContextProvider";
import { useRequestsFlagsContext } from "../../contexts/RequestsFlagsContext/RequestsFlagsContextProvider";
import AccountTable from "../../features/source-users/user-accounts-list/UserAccountsTable";
import NoAccountsInfo from "../../features/source-users/user-accounts-list/Table/NoAccountsInfo";
import AccountRequestList from "../../features/source-users/requests-tiles/AccountRequestList";

const MainPage: React.FC = () => {
  const accounts = useContext(GuestAccountContext);

  const flags = useRequestsFlagsContext();

  if (accounts === null) return <Loader />;

  return (
    <>
      <Text className="IP-bd__label">
        List of Your <b>existing</b> accounts in <b>Avanade</b> environment:
      </Text>
      {flags.anyAccountInUse ? <AccountTable /> : <NoAccountsInfo />}

      <hr className="IP-bd__separator" />
      <AccountRequestList
        avanadeGuestAccountInUse={
          //TODO: this flag should be moved inside this component
          flags.anyAccountInUse && flags.guestAccountRequestEnable
        }
      />
      <Outlet />
    </>
  );
};

export default MainPage;
