import { Icon, Text } from "@avanadeits/vangogh";

const NoAccountsInfo = () => {
  return (
    <>
      <Text
        className="IP-bd__noneAccountMsg"
        padding="5"
        radius="3"
        colorText="info"
        colorBorder="info"
      >
        <Icon name="info" colorText="info" size="s" /> You don't have Avanade
        account yet. Please <a href="/FindGroup">read guidance</a> on page
        before you request account.
      </Text>
    </>
  );
};

export default NoAccountsInfo;
