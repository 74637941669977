import { NavLink } from "react-router-dom";

type HeaderItemProps = {
  title: string;
  to: string;
};

const HeaderItem = ({ title, to }: HeaderItemProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        isPending
          ? "VG-nav__link pending"
          : isActive
          ? "VG-nav__link --is-active"
          : "VG-nav__link"
      }
    >
      {title}
    </NavLink>
  );
};

export default HeaderItem;
