import { useUserTenantsGatewayContext } from "../contexts/UserTenantsGatewayContext/UserTenantsGatewayContextProvider";
import { SourceTenantCrossUserAppRoutes } from "./SourceTenantCrossUserAppRoutes";
import { SourceTenantAppRoutes } from "./SourceTenantAppRoutes";
import { TargetTenantAppRoutes } from "./TargetTenantAppRoutes";

const AppRoutes = () => {
  const user = useUserTenantsGatewayContext();

  return (
    <div className="IP-bd">
      {user.tenant === "SourceTenant" && <SourceTenantAppRoutes />}
      {user.tenant === "TargetTenant" && <TargetTenantAppRoutes />}
      {user.tenant === "SourceTenantCrossUser" && (
        <SourceTenantCrossUserAppRoutes />
      )}
    </div>
  );
};

export default AppRoutes;
