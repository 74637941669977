import { Tr, Td, Badge, Button, Icon, Text } from "@avanadeits/vangogh";

type Props = {
  status: "InUse";
  enterpriseId: string;
};

export const GuestAccountRow = ({ status, enterpriseId }: Props) => {
  return (
    <Tr>
      <Td>
        <Text size="xl">
          <b>Guest Account</b>
        </Text>
        <Badge size="xxs" colorBackground="success-filled">
          <b>{status}</b>
        </Badge>
      </Td>
      <Td>- - -</Td>
      <Td>
        <div className="IP-table-online">
          <Button
            size="xs"
            colorBorder="default"
            onClick={() => {
              navigator.clipboard.writeText(enterpriseId);
            }}
          >
            <Icon name="content_copy" />
          </Button>
          <Text>
            {enterpriseId.split("#EXT#")[0]}
            <br />
            #EXT#{enterpriseId.split("#EXT#")[1]}
          </Text>
        </div>
      </Td>
      <Td>- - -</Td>
      <Td>- - -</Td>
      <Td>- - -</Td>
      <Td>- - -</Td>
      <Td>- - -</Td>
    </Tr>
  );
};
