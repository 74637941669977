import { MailboxRequestFormModel } from "../features/source-users/requests-forms/Mailbox/MailboxRequestFormModel";
import { TMailboxRequestType } from "../models/MailboxRequestType";

import ApiRoutes from "./config/ApiRoutes";
import { buildQueryString } from "./config/buildFilteredQuery";
import { get, postFormData, postLocal } from "./config/get";
import { FileFetcher } from "./config/getFile";
import FileFetchResult from "./responses/FileFetcherResult";
import { MailboxRequestResponse } from "./responses/MailboxRequestResponse";
import { MailboxRequestResponseItems } from "./responses/MailboxRequestResponseItems";

const mailboxRequestsService = {
  create: async (data: MailboxRequestFormModel) => {
    var request: FormData = new FormData();
    request.append("SourceEmail", data.accentureEmail!);
    request.append("ManagerEmail", data.avanadeMangerEnterpriseId!);
    request.append("ProjectParticipant", data.project.value);
    request.append("ProjectName", data.projectName);
    request.append("MailboxReason", data.accentureAccountNotInUserReason);
    request.append("MailboxBusinessNeed", data.businessReason);
    request.append("WbsCode", data.wbsCode);
    request.append("AttachmentContent", data.attachment!);
    request.append(
      "AttachmentBusinessException",
      data.attachmentBusinessException!
    );
    await postFormData(ApiRoutes.CreateMailboxRequest, request);
  },

  downloadMailboxRequestAttachment: async (
    guid: string,
    attachmentType: number
  ): Promise<FileFetchResult> => {
    const queryParams = attachmentType
      ? `?attachmentType=${attachmentType}`
      : "";
    const { data, fileContent } = await FileFetcher<FileFetchResult>(
      ApiRoutes.DownloadMailboxRequestAttachment + "/" + guid + queryParams,
      {
        method: "GET",
      }
    );

    return { data, fileContent };
  },

  all: async () => {
    const response = await get<MailboxRequestResponseItems[]>(
      ApiRoutes.GetAllMailboxRequests
    );
    return response.data;
  },

  allMailboxRequestsFiltered: async (
    searchTerm?: string,
    sortColumn?: string,
    sortOrder?: string,
    pageSize?: number,
    pageNumber?: number,
    currentType?: TMailboxRequestType
  ) => {
    const queryString = buildQueryString(
      searchTerm,
      sortColumn,
      sortOrder,
      pageSize,
      pageNumber,
      currentType
    );
    const response = await get<MailboxRequestResponse>(
      ApiRoutes.GetAllMailboxRequests + `?${queryString}`
    );

    return response.data;
  },

  getByGuid: async (guid: string) => {
    const response = await get<MailboxRequestResponseItems>(
      ApiRoutes.GetMailboxRequestByGuid + "/" + guid
    );
    return response.data;
  },

  approve: async (guid: string) => {
    const response = await postLocal(
      ApiRoutes.ApproveMailboxRequest + "/" + "approve" + "/" + guid
    );
    return response.data;
  },

  reject: async (guid: string, rejectReason: string) => {
    const response = await postLocal<MailboxRequestResponseItems>(
      ApiRoutes.RejectMailboxRequest + "/" + "reject",
      { requestGuid: guid, rejectReason: rejectReason }
    );
    return response.data;
  },
};

export default mailboxRequestsService;
