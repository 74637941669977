import { Modal, Icon, Loader, Button } from "@avanadeits/vangogh";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import accountGovernanceService from "../../services/accountGovernanceService";
import { useAzureBasicUserInfoContext } from "../../contexts/AzureBasicUserINdoContext/AzureBasicUserINdoContext";
import { useUserAccountListContext } from "../../contexts/CloudAccountContext/UserAccountListContextProvider";

export const PasswordResetPage = () => {
  const [show, setShow] = useState<boolean>(true);
  const [password, setPassword] = useState<string | null>(null);
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const basicUserAzureInfo = useAzureBasicUserInfoContext();
  const navigate = useNavigate();
  const [isCloud, setIsCloud] = useState<boolean>(false);

  const accounts = useUserAccountListContext();
  let passwordTimeout = 300000;

  useEffect(() => {
    if (accounts !== null) {
      for (const account of accounts) {
        if (account.status === "InUse" && account.type === "Cloud") {
          setIsCloud(true);
        }
      }
    }
    async function getTap() {
      if (accounts !== null) {
        for (const account of accounts) {
          if (account.status === "InUse" && account.type === "Cloud") {
            setShow(true);
            setPassword("");
            await accountGovernanceService.resetUserPassword(
              account.accountGuid
            );
          }
        }
      }
    }
    async function getPassword() {
      if (accounts !== null) {
        for (const account of accounts) {
          if (account.status === "InUse" && account.type === "OnPremise") {
            setShow(true);
            setPassword(null);
            var secret = await accountGovernanceService.resetUserPassword(
              account.accountGuid
            );

            if (secret?.isSuccessful) {
              setPasswordReset(true);
              setPassword(secret!.value);
              setTimeout(() => {
                setPassword("");
              }, passwordTimeout);
            } else setPassword(secret!.value);
          }
        }
      }
    }

    isCloud ? getTap() : getPassword();
  }, [accounts, isCloud, passwordTimeout]);

  return (
    <Modal show={true} setShow={setShow} hideCloseButton>
      <Modal.Header>
        <Icon name="info" colorText="error" /> <span>Notification!</span>
      </Modal.Header>
      <Modal.Body>
        {password === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "84px",
            }}
          >
            <Loader size="xs" />
            <p
              style={{
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {isCloud
                ? "Generating new tap password..."
                : "Generating new password..."}
            </p>
          </div>
        ) : (
          <div
            style={{
              height: "84px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isCloud ? (
              <TapComponent sourceEmail={basicUserAzureInfo.upn} />
            ) : (
              <PasswordComponent
                secret={password}
                isSuccessful={passwordReset}
              />
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xs"
          minWidth="6em"
          colorBorder="default"
          onClick={() => {
            setShow(false);
            navigate(-1);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type TapProps = {
  sourceEmail: string;
};
const TapComponent = ({ sourceEmail }: TapProps) => {
  return (
    <p style={{ margin: 0, textAlign: "center" }}>
      You should be receiving an e-mail to <b>{sourceEmail} </b>
      shortly with instructions on how to complete your password reset.<br></br>
      Please follow the instructions in the email to complete your password
      reset.
    </p>
  );
};

type PasswordProps = {
  secret: string;
  isSuccessful: boolean;
};
const PasswordComponent = ({ secret, isSuccessful }: PasswordProps) => {
  return isSuccessful ? (
    <p style={{ margin: 0 }}>
      Here is the new password for your on premise account: <b>{secret}</b>
      <br />
      Please write it down as it will disappear in 5 minutes.
    </p>
  ) : (
    <p style={{ margin: 0 }}>Request failed: {secret}</p>
  );
};
