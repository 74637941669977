import { Text, Icon } from "@avanadeits/vangogh";

export type AccountRequestProps = {
  size: 1 | 2;
  to: string;
  isActive: boolean;
  notEligible: boolean;
  logo: Array<LogoDetails> | "Empty";
  title: JSX.Element;
  shortDescription: string;
  activeRequestLinkIcon: string;
  description?: string;
};

export type LogoDetails = {
  src: string;
  alt: string;
};

type RequestTemplateProps = Pick<AccountRequestProps, "isActive" | "to"> & {
  children: React.ReactNode;
};

const RequestTemplate = ({ isActive, children, to }: RequestTemplateProps) => {
  return isActive ? (
    <a href={to} className={`IP-card__inner`}>
      {children}
    </a>
  ) : (
    <div className="IP-card__inner">{children} </div>
  );
};

type ActiveRequestProps = Pick<
  AccountRequestProps,
  "activeRequestLinkIcon" | "shortDescription"
>;

const ActiveRequest = ({
  activeRequestLinkIcon,
  shortDescription,
}: ActiveRequestProps) => {
  return (
    <>
      <Text colorText="primary" className="IP-card__action">
        <span className="VG-text-h6">Request</span>
        <Icon colorText="primary" name={activeRequestLinkIcon} />
      </Text>
      <Text className="IP-card__shortDesc">
        <small>{shortDescription}</small>
      </Text>
    </>
  );
};

type InActiveRequestProps = Pick<AccountRequestProps, "notEligible">;

const InActiveRequest = ({ notEligible }: InActiveRequestProps) => {
  return (
    <>
      <Text colorText="success" className="IP-card__action">
        <span className="VG-text-h6">
          {notEligible ? "Not eligible" : "Already created"}
        </span>
        {notEligible || <Icon colorText="success" name="done" />}{" "}
      </Text>
      <Text className="IP-card__shortDesc"></Text>
    </>
  );
};

type DisableAccountRequestProps = Pick<AccountRequestProps, "title">;

export const DisableAccountRequest = ({
  title,
}: DisableAccountRequestProps) => {
  return (
    <div className="IP-card --make-disabled">
      <div className="IP-card__inner">
        <Text className="IP-card__desc" size="s"></Text>
        <span className="IP-card__identity"></span>
        <Text className="IP-card__title">
          <span className="VG-text-h4">{title}</span>
        </Text>
        <Text colorText="primary" className="IP-card__action">
          <span className="VG-text-h6">Coming soon.</span>
          <Icon colorText="primary" name="do_not_disturb_on" />
        </Text>
        <Text className="IP-card__shortDesc">
          <small>Work in progress...</small>
        </Text>
      </div>
    </div>
  );
};

const AccountRequest = ({
  size,
  to,
  isActive,
  logo,
  title,
  activeRequestLinkIcon,
  shortDescription,
  description,
  notEligible,
}: AccountRequestProps) => {
  const cardSize = (size: 1 | 2): string => {
    if (size == 1) return "";
    else return "size-2";
  };

  return (
    <div
      className={`IP-card ${cardSize(size)} ${isActive ? "" : "--is-inuse"}`}
    >
      <RequestTemplate isActive={isActive} to={to}>
        <Text className="IP-card__desc" size="s">
          <small>{description}</small>
        </Text>
        <span className="IP-card__identity">
          {logo != "Empty" ? (
            logo.map((x) => (
              <img key={x.alt + "logo"} src={x.src} alt={x.alt} />
            ))
          ) : (
            <></>
          )}
        </span>
        <Text className="IP-card__title">
          <span className="VG-text-h4">{title}</span>
        </Text>
        {isActive ? (
          <ActiveRequest
            activeRequestLinkIcon={activeRequestLinkIcon}
            shortDescription={shortDescription}
          />
        ) : (
          <InActiveRequest notEligible={notEligible} />
        )}
      </RequestTemplate>
    </div>
  );
};

export default AccountRequest;
