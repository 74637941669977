import { Button, Text } from "@avanadeits/vangogh";
import { useNavigate } from "react-router-dom";

export type RequestType =
  | "Avanade Account"
  | "Mailbox and MS Teams"
  | "On-Premise Account";

type RequestTypeProps = {
  type: RequestType;
};

export const RequestTypeInfo = (props: RequestTypeProps) => {
  const navigate = useNavigate();

  const to = "/";

  return (
    <div className="IP-pagination">
      <Button
        colorBorder="default"
        icon="chevron_left"
        onClick={() => {
          navigate(to);
        }}
      >
        Return
      </Button>
      <Text>
        <a href="/">My Accounts</a> / Request for: {props.type}
      </Text>
    </div>
  );
};
